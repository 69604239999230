import { Box, Flex, Img } from '@chakra-ui/react';
import { useState } from 'react';

import { OkGestureGuy, PointingPhoneGuy } from '../../assets/Images';
import MonthlyPaymentCalculator from '../../components/MonthlyPaymentCalculator/MonthlyPaymentCalculator';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import Aside from './Aside';
import Bottom from './Bottom';
import Questions from './Questions';

const PaymentCalculatorPage = () => {
  const [showInputs, setShowInputs] = useState<boolean>(true);

  return (
    <Box pb="100px" mx="auto">
      <TitleContainer mb={12}>
        <Title fontSize={{ base: '32px', md: '48px' }}>
          Picture Your New Monthly Payment with Our Lease Buyout Calculator{' '}
        </Title>
      </TitleContainer>
      <Flex mb="20px" flexDirection={{ base: 'column', lg: 'row' }}>
        <Flex flexDirection="column" minW={{ lg: 'fit-content' }} h="fit-content">
          <Flex position="relative">
            <Img
              position="absolute"
              display={{ base: 'none', lg: 'block' }}
              width="143px"
              height="150px"
              zIndex={1}
              src={PointingPhoneGuy}
              top="-45px"
              left="-92px"
              opacity={showInputs ? 100 : 0}
              transition="opacity 0.3s"
            />
            <Img
              position="absolute"
              display={{ base: 'none', lg: 'block' }}
              w="154px"
              h="200px"
              zIndex={1}
              src={OkGestureGuy}
              top="-70px"
              left="-64px"
              opacity={!showInputs ? 100 : 0}
              transition="opacity 0.3s"
            />
            <MonthlyPaymentCalculator setParentShowInputs={setShowInputs} />
          </Flex>
          <Questions />
        </Flex>
        <Aside />
      </Flex>
      <Bottom />
    </Box>
  );
};

export default PaymentCalculatorPage;
