import { Container, Link, Text, TextProps } from '@chakra-ui/react';

import Description from '../components/Description';
import Title from '../components/Title';
import TitleContainer from '../components/TitleContainer';

const SectionLabel = ({ children }: TextProps) => (
  <Text
    fontSize="20px"
    mb="5px"
    color="leaseEndBlue"
    fontWeight="semibold"
    letterSpacing="1px"
    textTransform="uppercase"
  >
    {children}
  </Text>
);

const StyledDescription = ({ children }: TextProps) => (
  <Description m="10px 0 20px 10px">{children}</Description>
);

const EOY2024Terms = () => {
  return (
    <Container maxW="1150px" mx="auto" mb="100px">
      <TitleContainer>
        <Title fontSize={{ base: '36px', md: '52px' }}>
          Lease End Year-End Rate Special Terms and Conditions
        </Title>
      </TitleContainer>

      <Text mb={8} fontSize="16px">
        Last Updated: 12/25/2024
      </Text>

      <SectionLabel>Effective Dates:</SectionLabel>
      <StyledDescription>
        This promotional offer is valid beginning{' '}
        <Text as="span" fontWeight={700}>
          12/26/2024 at 12:00 AM MST
        </Text>{' '}
        and expires on
        <Text as="span" fontWeight={700}>
          1/3/2025 at 11:59 PM MST
        </Text>
        . All qualifying loans must be funded by{' '}
        <Text as="span" fontWeight={700}>
          02/01/2025
        </Text>{' '}
        to receive the promotional rate.
      </StyledDescription>
      <SectionLabel>Annual Percentage Rate (APR):</SectionLabel>
      <StyledDescription>
        The advertised 0.5% rate reduction applies to the Annual Percentage Rate (APR) and is
        subject to credit approval. The actual APR may vary depending on the applicant's
        creditworthiness, loan amount, and loan term. This reduction is applicable only to
        qualifying loans that meet the terms outlined in this agreement.
      </StyledDescription>
      <SectionLabel>Eligibility Criteria:</SectionLabel>
      <StyledDescription>
        To qualify for the promotional rate reduction, applicants must secure a new loan with a
        minimum loan amount of{' '}
        <Text as="span" fontWeight={700}>
          $20,000
        </Text>
        . This offer does not apply to the refinancing of existing loans. Loan applications must be
        submitted and approved during the promotional period, and funding must occur no later than{' '}
        <Text as="span" fontWeight={700}>
          02/01/2025
        </Text>{' '}
        to be eligible for the promotional rate.
      </StyledDescription>
      <SectionLabel>Example Loan Savings:</SectionLabel>
      <StyledDescription>
        For illustrative purposes, an APR reduction from 8.0% to 7.5% on a loan amount of $29,000
        with a 72-month term results in total savings of approximately $507.65. These savings are
        calculated based on the reduction in monthly payments over the loan term. Actual savings may
        vary depending on the specific loan amount, term, and APR offered to the applicant.
      </StyledDescription>
      <SectionLabel>Additional Terms:</SectionLabel>
      <StyledDescription>
        Approval for loans and the promotional rate are not guaranteed and are subject to Lease
        End's standard underwriting criteria. This promotion cannot be combined with any other
        discounts or special offers. Additional terms, conditions, and fees may apply as determined
        by Lease End's lending policies.
      </StyledDescription>
      <SectionLabel>Equal Opportunity Lender:</SectionLabel>
      <StyledDescription>
        Lease End is an Equal Opportunity Lender. All loans are subject to compliance with
        applicable federal, state, and local laws, as well as Lease End’s internal policies and
        procedures.
      </StyledDescription>
      <SectionLabel>Contact Information:</SectionLabel>
      <StyledDescription>
        For more information about this promotional offer, please contact Lease End at{' '}
        <Link href="tel:8449022842" fontWeight={700}>
          (844) 902-2842
        </Link>
        . Our representatives are available to assist you with any questions regarding the terms and
        conditions of this promotion.
      </StyledDescription>
    </Container>
  );
};

export default EOY2024Terms;
