/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { BoxProps, Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik, FormikContextType } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { LDFlags } from '../constants/experiments';
import { statesArray } from '../constants/states';
import {
  FLOW_ENTRY,
  KWLP_LEASE_BUYOUT,
  KWLP_LEASE_BUYOUT_RATES,
  KWLP_LEASE_BUYOUT_SELL,
} from '../constants/urls';
import { INVALID_VIN_MSG } from '../constants/vin';
import { TemporaryShoppingCartDataInput } from '../gql/generated/graphql';
import { useFlag } from '../hooks';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import { NonNullRequired } from '../utils/types/typeHelpers';
import validateLicenseNumber, {
  INVALID_LICENSE_PLATE_MSG,
} from '../utils/validation/licenseNumberValidator';
import validateVin from '../utils/validation/vinValidator';
import BigGameGiveaway from './PromoBanners/BigGameGiveaway';
import Input from './formComponents/Input';
import Select from './formComponents/Select';

const licensePlateValidationSchema = Yup.object({
  license_plate_number: Yup.string()
    .required('Required field')
    .test('is-valid-license-number', INVALID_LICENSE_PLATE_MSG, async (value) =>
      validateLicenseNumber({ licenseNumber: value }),
    ),
  state: Yup.string().required('Required field'),
});

const vinValidationSchema = Yup.object({
  vin: Yup.string()
    .required('Required field')
    .test('is-valid-vin', INVALID_VIN_MSG, (value) => validateVin(value)),
});

const LICENSE_PLATE_NAME = 'PLATE';

type FormFields = Yup.InferType<typeof licensePlateValidationSchema & typeof vinValidationSchema>;
type FormikContext = FormikContextType<FormFields>;
type setErrors = FormikContext['setErrors'];
type setValues = FormikContext['setValues'];

export const SHOPPING_CART_PARAMS = {
  PAYOFF: 'payoff',
  CREDIT_SCORE: 'credit_score',
  DOWN_PAYMENT: 'down_payment',
  TERM: 'term',
};

// will need to be synced with com
export const FLOW_ENTRY_PARAMS = {
  ZIP: 'zip',
  VIN: 'vin',
  LICENSE_PLATE: 'license_plate',
  STATE: 'state',
};

const createQueryParams = (
  values: FormFields,
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>,
  zip?: string,
) => {
  const { vin, license_plate_number, state } = values;
  const { buyout, credit_score, down_payment, loan_term } = shoppingCart || {};

  const params = {
    [FLOW_ENTRY_PARAMS.LICENSE_PLATE]: license_plate_number,
    [FLOW_ENTRY_PARAMS.STATE]: state,
    [FLOW_ENTRY_PARAMS.VIN]: vin,
    [FLOW_ENTRY_PARAMS.ZIP]: zip,
    [SHOPPING_CART_PARAMS.PAYOFF]: buyout,
    [SHOPPING_CART_PARAMS.CREDIT_SCORE]: credit_score,
    [SHOPPING_CART_PARAMS.DOWN_PAYMENT]: down_payment,
    [SHOPPING_CART_PARAMS.TERM]: loan_term,
  };

  const cleanedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    return { ...acc, [key]: value.toString() };
  }, {});

  return new URLSearchParams(cleanedParams).toString();
};

interface LicensePlateOrVinInput_LPRatesProps extends BoxProps {
  autoFocus?: boolean;
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>;
  zip?: string;
  cta?: string;
  title?: string;
  prequal?: boolean;
}

const LicensePlateOrVinInput_LPSimple = ({
  autoFocus = false,
  shoppingCart,
  zip,
  cta,
  title,
  prequal = false,
  ...rest
}: LicensePlateOrVinInput_LPRatesProps) => {
  const [savedQueryParams] = useCookie<string>(CookieKeys.QUERY_PARAMS);
  const { search, pathname } = useLocation();
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const [dealExists, setDealExists] = useState(false);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;
  const SUPERBOWL2025 = useFlag(LDFlags.SUPERBOWL2025);

  const handleWarningsReset = () => {
    setDealExists(false);
  };

  const handleOnSwitchChange = (
    setErrors: setErrors,
    setValues: setValues,
    newValue: string,
  ): void => {
    setCurrentInput(newValue);
    setValues({ vin: '', license_plate_number: '', state: '' });
    setErrors({});
  };

  const handleSubmit = async (values: FormFields) => {
    const urlParams = new URLSearchParams(search || savedQueryParams || '');
    const queryParams = createQueryParams(values, shoppingCart, zip);
    let appendParams = '';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        appendParams += `&${key}=${value}`;
      });
    }
    rudderanalytics.track(RudderEvent.FlowEntry, {
      page: pathname,
      method: isLicensePlate ? 'License Plate' : 'VIN',
    });
    if (prequal) {
      rudderanalytics.track(RudderEvent.LabTest, {
        name: 'Prequal',
        value: 'StartFlow',
      });
    }
    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.open(`${FLOW_ENTRY}?${queryParams + appendParams}`, '_self');
    }, 1);
  };

  const tracking = () => {
    return null;
  };

  useEffect(() => {
    tracking();
  }, []);

  return (
    <Formik<FormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        license_plate_number: '',
        state: '',
        vin: '',
      }}
      validationSchema={isLicensePlate ? licensePlateValidationSchema : vinValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setErrors, setValues }) => (
        <Form style={{ width: '100%' }}>
          <Flex
            bg="#E3F1F8"
            borderWidth={4}
            borderColor="#cde2ed"
            shadow="lg"
            w={{
              base: '100%',
            }}
            // minW={{ base: '90vw', md: '200px' }}
            borderRadius={8}
            p="4"
            flexDirection="column"
            {...rest}
          >
            <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
              <Text
                w="100%"
                color="#012D5D"
                borderRadius={4}
                textAlign="left"
                fontSize={16}
                fontWeight={800}
              >
                {title ? (
                  <>{title}</>
                ) : (
                  <>GET STARTED WITH YOUR {isLicensePlate ? 'LICENSE PLATE' : `VEHICLE'S VIN`}</>
                )}
              </Text>
            </Flex>
            <Flex mx={0} mt={3} minH="50px">
              {isLicensePlate ? (
                <>
                  <Flex w="65%">
                    <Input
                      name="license_plate_number"
                      _container={{
                        bg: '#fff',
                        borderWidth: 1,
                        borderColor: '#98CBE8',
                        borderRightWidth: 0,
                        borderRadius: 5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        py: 1,
                        ps: 1,
                        pe: 0,
                      }}
                      _input={{
                        borderWidth: 0,
                        borderRightWidth: 1,
                        borderColor: '#98CBE8',
                        borderRadius: 0,
                        fontSize: 20,
                        fontWeight: 500,
                        h: 14,
                        letterSpacing: 0,
                        textTransform: 'none',
                      }}
                      placeholder="License Plate"
                      autoFocus={autoFocus}
                      textTransform="uppercase"
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                  <Flex flex={1}>
                    <Select
                      _container={{
                        bg: '#fff',
                        borderWidth: 1,
                        borderLeftWidth: 0,
                        borderColor: '#98CBE8',
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        py: 1,
                        pe: 1,
                        ps: 0,
                      }}
                      _control={{
                        border: 0,
                        fontSize: 20,
                        fontWeight: 500,
                        height: 14,
                        textTransform: 'none',
                      }}
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="Your vehicle's 17-digit VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists}
                  _container={{
                    bg: '#fff',
                    borderWidth: 1,
                    borderColor: '#98CBE8',
                    borderRadius: 5,
                    p: 1,
                  }}
                  _input={{
                    borderWidth: 0,
                    borderRadius: 0,
                    fontSize: 20,
                    fontWeight: 500,
                    h: 14,
                    letterSpacing: 0,
                    textTransform: 'none',
                  }}
                />
              )}
            </Flex>
            <Flex flex={1} w="100%" gap={4} alignItems="center" mt={2} ms={2}>
              <Text
                onClick={() =>
                  handleOnSwitchChange(setErrors, setValues, isLicensePlate ? 'VIN' : 'PLATE')
                }
                cursor="pointer"
                fontSize={14}
                fontWeight={600}
                color="oceanBoatBlue"
                textAlign="left"
              >
                {isLicensePlate ? 'Use VIN' : 'Use License Plate'}
              </Text>
            </Flex>
          </Flex>
          <Flex
            w={{
              base: '100%',
            }}
            flex={1}
            justifyContent="flex-end"
            pos="relative"
            zIndex={3}
            position="relative"
          >
            {SUPERBOWL2025 && <BigGameGiveaway />}
            <Button
              zIndex={1}
              type="submit"
              mt="-16px"
              me="16px"
              py="28px"
              fontWeight={700}
              borderRadius={8}
              isLoading={isSubmitting}
              textTransform="uppercase"
              fontSize={16}
              borderWidth={4}
              borderColor="rgba(0,0,0,0.05)"
            >
              {pathname === KWLP_LEASE_BUYOUT
                ? 'Buyout My Lease Early'
                : pathname === KWLP_LEASE_BUYOUT_RATES
                ? 'Find My Rate'
                : pathname === KWLP_LEASE_BUYOUT_SELL
                ? 'Unlock My Equity'
                : cta || 'Find My Loan'}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LicensePlateOrVinInput_LPSimple;
