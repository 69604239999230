import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LDFlags } from '../constants/experiments';
import { EXCLUDE_SUPER_BOWL_PROMO } from '../utils/globals';
import useFlag from './useFlag';

const useSuperBowl = () => {
  const SUPERBOWL2025 = useFlag(LDFlags.SUPERBOWL2025);
  const { pathname } = useLocation();
  const [superBowl, setSuperBowl] = useState<boolean>(false);
  useEffect(() => {
    setSuperBowl(SUPERBOWL2025 && !EXCLUDE_SUPER_BOWL_PROMO.includes(pathname));
  }, []);

  return superBowl;
};

export default useSuperBowl;
