import { Box, Flex, Link } from '@chakra-ui/react';

import Description from '../../components/Description';
import Title from '../../components/Title';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { BLOG_SANITY } from '../../constants/urls';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { BlogPostProps } from '../Sanity/Model';
import Author from './Author';
import TagLink from './TagLink';

/* eslint-disable react/destructuring-assignment */

interface PostProps {
  post: BlogPostProps;
}

const BlogPostSnippet = (props: PostProps) => {
  return (
    <>
      <Box
        flexDirection="column"
        mt={{
          base: '15px',
          lg: '30px',
        }}
        pt={{
          base: '15px',
          lg: '30px',
        }}
        borderTopWidth={{
          base: 1,
        }}
        borderRadius={8}
      >
        <Title
          textAlign="left"
          fontSize={{
            base: '16pt',
            lg: '20pt',
          }}
          cursor="pointer"
        >
          <Link
            color="inherit"
            _hover={{
              color: 'inherit',
            }}
            href={`${BLOG_SANITY}/${props.post.slug.current}`}
            title={props.post.title}
          >
            {props.post.title}
          </Link>
        </Title>
        <Author post={props.post} showDate />
        {props.post.mainImage && (
          <Flex mb={3}>
            <BlogImageComponent value={props.post.mainImage} isInline={false} />
          </Flex>
        )}
        <Flex>
          <Flex flexDirection="column" flexGrow={1}>
            <Description textAlign="left">{props.post.snippet}</Description>
            {props.post.categories && props.post.categories.length && (
              <>
                <Flex
                  mt={4}
                  mb={4}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  gap={1}
                >
                  {props.post.categories.map((category: string) => {
                    return <TagLink tag={category} />;
                  })}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
        <Flex>
          <PrimaryButton>
            <Link
              color="inherit"
              _hover={{
                color: 'inherit',
              }}
              title={props.post.title}
              href={`${BLOG_SANITY}/${props.post.slug.current}`}
            >
              READ ARTICLE
            </Link>
          </PrimaryButton>
        </Flex>
      </Box>
    </>
  );
};

export default BlogPostSnippet;
