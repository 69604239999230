/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable func-names */

/* eslint-disable security/detect-non-literal-fs-filename */
import { useMutation, useQuery } from '@apollo/client';
import { Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import {
  VIPPhoneFetchDocument,
  VIPPhoneImportDocument,
  VIPPhoneUpdateDocument,
} from '../gql/vipPhoneGql';
import useUrlGuid from '../hooks/useUrlGuid';
import { VipInfo } from '../pages/Home/LPVIPConsultation';
import Loading from '../pages/Loading';
import { VIP_PHONE_CONSTANTS, phoneRegex } from '../utils/globals';
import { rudderanalytics } from '../utils/rudderstack';
import Input from './formComponents/Input';

interface FormProps {
  guid: string | null | undefined;
  setDone: (done: boolean) => unknown;
  setVip: Function;
}

const vipValidationSchema = Yup.object({
  phone: Yup.string().required('Required').matches(phoneRegex, 'Invalid phone.'),
});

const UhOh = () => {
  return (
    <Flex>
      <Text>Ruh-roh</Text>
    </Flex>
  );
};

type FormFields = Yup.InferType<typeof vipValidationSchema>;
interface VIP {
  phone_number: string;
}

const VIPForm = ({ guid, setDone, setVip }: FormProps) => {
  const [updateVIPPhone] = useMutation(VIPPhoneUpdateDocument);
  const [importVIPPhone] = useMutation(VIPPhoneImportDocument);
  const { loading, data: tempInfo } = useQuery(VIPPhoneFetchDocument, {
    variables: { id: guid },
    notifyOnNetworkStatusChange: true,
    context: {
      isErrorHandled: true,
    },
  });
  const [vipError, setVipError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [vipInfo, setVipInfo] = useState<VipInfo | null>(null);

  const handleImport = async () => {
    await importVIPPhone({
      variables: {
        id: guid,
        transformType: VIP_PHONE_CONSTANTS.TransformType,
        importType: VIP_PHONE_CONSTANTS.ImportType,
      },
    })
      .then((response) => {
        if (response.errors) {
          setVipError(true);
          return;
        }
        setDone(true);
        rudderanalytics.identify({
          deal_id: response.data.temporaryInfoImport.id,
          customer_id: response.data.temporaryInfoImport.customer.id,
        });
      })
      .catch(() => {
        setVipError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmit = async (values: FormFields) => {
    if (!guid) {
      // TODO: proper error hanlding in UI
      // eslint-disable-next-line no-alert
      alert("We're having little issue right now, sorry!");
      return null;
    }
    setSubmitting(true);
    const data: VIP = {
      phone_number: values.phone,
    };
    await updateVIPPhone({
      variables: {
        info: {
          id: guid,
          data,
        },
      },
    })
      .then(() => {
        setVipError(false);
        handleImport();
      })
      .catch(() => {
        setVipError(true);
      });
    return null;
  };

  useEffect(() => {
    if (tempInfo && tempInfo.temporaryInfo) {
      setVipInfo(tempInfo.temporaryInfo.data);
      setVip({
        firstName: tempInfo.temporaryInfo.data.first_name || '',
        lastName: tempInfo.temporaryInfo.data.last_name || '',
        make: tempInfo.temporaryInfo.data.make || '',
        model: tempInfo.temporaryInfo.data.model || '',
        year: tempInfo.temporaryInfo.data.year || '',
      });
    }
  }, [tempInfo]);

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : !tempInfo.temporaryInfo ? (
        <UhOh />
      ) : (
        <Formik<FormFields>
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            phone: '',
          }}
          validationSchema={vipValidationSchema}
          onSubmit={handleSubmit}
        >
          <Form style={{ width: '100%' }}>
            <Flex
              bg="#fff"
              border="1px solid"
              borderColor="#98CBE8"
              shadow="lg"
              mx="auto"
              w={{
                base: '100%',
                md: '500px',
              }}
              // minW={{ base: '90vw', md: '200px' }}
              rounded="10px"
              p="4"
              flexDirection="column"
            >
              <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
                <Text
                  bg="#007dc5"
                  w="100%"
                  color="#fff"
                  borderRadius={4}
                  textAlign="center"
                  fontSize={16}
                  fontWeight={600}
                  py={2}
                >
                  {vipInfo ? (
                    <>
                      WE'LL HELP YOU BUY YOUR{' '}
                      <Text as="span" fontWeight={800}>
                        {`${vipInfo.year} ${vipInfo.model?.toLocaleUpperCase()}`}
                      </Text>{' '}
                      TODAY
                    </>
                  ) : (
                    "WE'LL REACH OUT TO HELP END YOUR LEASE TODAY"
                  )}
                </Text>
              </Flex>
              <Flex mx={0} mt={3} minH="50px">
                {vipError && (
                  <Flex>
                    <Text>Sorry, there was an issue</Text>
                  </Flex>
                )}
                <Input
                  mask="(999) 999-9999"
                  name="phone"
                  placeholder="Your phone number"
                  textTransform="uppercase"
                  _container={{
                    bg: '#fff',
                    borderWidth: 1,
                    borderColor: '#98CBE8',
                    borderRadius: 5,
                    p: 1,
                  }}
                  _input={{
                    borderWidth: 0,
                    borderRadius: 0,
                    fontSize: 20,
                    fontWeight: 500,
                    h: 14,
                    letterSpacing: 0,
                    textTransform: 'none',
                  }}
                  isDisabled={submitting}
                />
              </Flex>
              <Flex flex={1} w="100%" gap={4} alignItems="center" mt={3}>
                <Button
                  type="submit"
                  py="28px"
                  flex={1}
                  fontWeight="600"
                  isLoading={submitting}
                  fontSize={16}
                >
                  CALL ME!
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Formik>
      )}
    </>
  );
};

const Done = () => {
  return (
    <Flex>
      <Text>Thank you</Text>
    </Flex>
  );
};

interface LicensePlateOrVinInput_VIPConsultProps {
  setVip: Function;
}

const LicensePlateOrVinInput_VIPConsult = ({ setVip }: LicensePlateOrVinInput_VIPConsultProps) => {
  const guid: string | null = useUrlGuid();
  const [done, setDone] = useState<boolean>(false);
  const init = async () => {
    if (guid && !done) {
      rudderanalytics.identify({ guid });
    }
    return null;
  };
  useEffect(() => {
    init();
  }, []);
  if (done) {
    return <Done />;
  }
  if (!guid) {
    return <UhOh />;
  }
  return <VIPForm guid={guid} setDone={setDone} setVip={setVip} />;
};

export default LicensePlateOrVinInput_VIPConsult;
