import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';

const Item = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <AccordionItem borderWidth="1px" rounded="lg" mb={2}>
      <AccordionButton>
        <Box
          flex="1"
          textAlign="left"
          color="oceanBoatBlue"
          fontSize={18}
          p={4}
          fontFamily="Monorale"
          fontWeight={500}
        >
          {title}
        </Box>
        <AccordionIcon color="oceanBoatBlue" />
      </AccordionButton>
      <AccordionPanel pl={2} pt={0} color="leaseEndBlue" mx={6}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

const Questions = () => {
  return (
    <Accordion allowToggle mt={4} maxW={{ base: 'none', lg: '680px' }}>
      <Item title="What do I need to do to get started?">
        To get started, we only need the information required to confirm your car's payoff or payoff
        amount from your lender. The information required varies by lender, but usually we'll ask
        for:
        <b>
          <UnorderedList>
            <ListItem>Your name</ListItem>
            <ListItem>Basic information about the primary lease holder</ListItem>
            <ListItem>Lease account number</ListItem>
            <ListItem>Vehicle Identification Number (VIN)</ListItem>
            <ListItem>Vehicle miles</ListItem>
          </UnorderedList>
        </b>
        Once we have that information, all you'll need to do is sit back and let us take care of the
        rest. This includes arranging financing, titling, registration, and the dreaded DMV visit
        (if you decide to <b>buy out</b> your car.).
        <br />
        <br />
        Check out how the process works{' '}
        <b>
          <a href="https://www.leaseend.com/how-it-works">here</a>.
        </b>
      </Item>
      <Item title="How long does the lease-end process take?">
        There are a few moving pieces involved with ending a car lease, and the total time to
        process your transaction can vary depending on who your auto lender is and what state you
        live in. However, it usually takes <b>less than 15 minutes</b> to get started - after that,
        our expert advisors take over and expedite the entire process for you.
      </Item>
      <Item title="What exactly is a lease payoff?">
        There are 3 ways you can end a car lease. You can turn the car back in to the dealership,{' '}
        <b>sell the car</b> , or <b>buy and keep the car</b>. If your lease is <b>bought out</b>,
        you're taking either the second or third option (selling the car, or buying it for
        yourself).
        <br />
        <br />
        Your leased car has a pre-determined <b>residual value</b> (the value of the car at the end
        of the lease). This value was established in your contract when you first agreed to lease,
        and it helps determine the <b>payoff amount</b> (the amount it would cost to purchase the
        vehicle so the manufacturer no longer holds the title). Every lender has their own specific
        way of calculating payoff amount, but generally speaking, it's more or less equal to the sum
        of the residual value plus any applicable taxes and fees.
        <br />
        <br />
        If you're leasing a car and decide that you want to keep it, you can <b>buy out</b> your
        lease, meaning you're purchasing the car and putting it in your name. If you decide you can
        make some money off your lease and want to sell it, someone else may be able to{' '}
        <b>purchase</b> the car and put it in their name. Either way, the car's new owner will have
        to pay the <b>payoff amount</b>, either up front or through financing, in order to transfer
        ownership of the car from the manufacturer.
      </Item>
      <Item title="How is my lease payoff price determined?">
        Your payoff amount is provided by the current lienholder and is calculated using the{' '}
        <b>residual value</b> for your car established in your original lease contract, plus{' '}
        <b>any remaining customer obligations</b>.
        <br />
        <br />
        When you work with us, you'll need to confirm your exact payoff amount with your lender, but
        don't worry - <b>we provide assistance</b> and instructions on how to do this.
      </Item>
    </Accordion>
  );
};

export default Questions;
