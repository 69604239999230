/* eslint-disable @typescript-eslint/ban-types */
import {
  Button,
  ButtonProps,
  Container,
  Flex,
  FlexProps,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { DkLpBgSub, ManWithLoudSpeaker } from '../../assets/Images';
import Loader from '../../components/Loader';
import Title from '../../components/Title';
import { CONTACT_US_EMAIL } from '../../constants/contact';
import { PRFetch, companyNewsFetch } from '../Sanity/Data';
import { CompanyNewsProps, PressReleaseProps } from '../Sanity/Model';
import News from './TabNews';
import PR from './TabPress';

interface InfoSecProps extends FlexProps {
  h1: string;
  url: string;
  subtext: string;
  cta?: string;
}

const InfoSec = ({ h1, url, subtext, cta = 'GO', ...rest }: InfoSecProps) => {
  const jump = () => {
    window.location.href = url;
  };
  return (
    <>
      <Flex px={8} py={12} borderWidth={1} borderRadius={8} flexDir="column" gap={4} {...rest}>
        <Title fontSize={28} fontWeight={800} m={0} textAlign="left">
          {h1}
        </Title>
        <Text fontSize={16} color="#444">
          {subtext}
        </Text>
        <Flex>
          <Button onClick={() => jump()}>{cta}</Button>
        </Flex>
      </Flex>
    </>
  );
};

interface TabButtonProps extends ButtonProps {
  label: string;
}

const TabButton = ({ label, ...rest }: TabButtonProps) => {
  return (
    <>
      <Button
        borderRadius={8}
        fontSize={20}
        letterSpacing={0}
        _hover={{
          background: '#f3f3f3',
        }}
        py={8}
        {...rest}
      >
        {label}
      </Button>
    </>
  );
};

const Press = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pressReleases, setPressReleases] = useState<PressReleaseProps[] | null>(null);
  const [companyNews, setCompanyNews] = useState<CompanyNewsProps[] | null>(null);
  const [tab, setTab] = useState<string>('pr');
  const isMobile = useBreakpointValue({ base: true, md: false });

  const fetch = async () => {
    setLoading(true);
    if (tab === 'news') {
      await companyNewsFetch(null, false)
        .then((data: CompanyNewsProps[] | null) => {
          setCompanyNews(data);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      await PRFetch(null, false)
        .then((data: PressReleaseProps[] | null) => {
          setPressReleases(data);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
    return null;
  };

  useEffect(() => {
    fetch();
  }, [tab]);

  return (
    <>
      <Container maxW="container.xl">
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          bgColor="002d5d"
          bgImage={DkLpBgSub}
          backgroundPosition="bottom center"
          backgroundSize="cover"
          px={{
            base: 16,
            lg: 8,
          }}
          pt={{
            base: 10,
            md: 20,
          }}
          pb={100}
        >
          <Flex
            alignItems={{
              base: 'center',
              md: 'flex-end',
            }}
            gap={{ base: 4, md: 8 }}
            flexDir={{
              base: 'column',
              md: 'row',
            }}
          >
            <Image
              src={ManWithLoudSpeaker}
              w={{
                base: '75px',
                md: '90px',
              }}
            />
            <Flex flexDir="column">
              <Title
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                color="white"
                fontSize={48}
                fontWeight={800}
                m={{
                  base: '24px 0 !important',
                  md: 0,
                }}
                p={0}
                mb={4}
                lineHeight={{
                  base: 1,
                  md: 1.4,
                }}
              >
                Press Resources
              </Title>
              <Text
                fontWeight={400}
                fontSize={18}
                color="white"
                w={{
                  base: '100%',
                  md: '55%',
                }}
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
              >
                Helpful links and downloads to help spread the word about Lease End. Don’t see what
                you are looking for?{' '}
                <Link
                  _hover={{
                    color: 'white',
                    textDecoration: 'none',
                  }}
                  textDecoration="underline"
                  fontWeight={600}
                  color="white"
                  href={`mailto: ${CONTACT_US_EMAIL}`}
                >
                  Contact us
                </Link>{' '}
                and we’ll help with anything you need.
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          gap={8}
          justifyContent="space-around"
          flexDir={{
            base: 'column',
            md: 'row',
          }}
        >
          <InfoSec
            w={{
              base: '100%',
              md: '33.33%',
            }}
            h1="About Us"
            url="/assets/LeaseEndExecutiveLeadershipBios.pdf"
            subtext="Learn about the founders"
            cta="DOWNLOAD"
          />
          <InfoSec
            w={{
              base: '100%',
              md: '33.34%',
            }}
            h1="Fact Sheet"
            url="/assets/LeaseEndFactSheet.pdf"
            subtext="Overview, facts, partners"
            cta="DOWNLOAD"
          />
          <InfoSec
            w={{
              base: '100%',
              md: '33.33%',
            }}
            h1="Brand Assets"
            url="/assets/LeaseEndMediaResources.zip"
            subtext="Approved media assets"
            cta="DOWNLOAD"
          />
        </Flex>
        <Flex pos="relative" zIndex={2} mt={16} borderBottomWidth={1} pb={2}>
          <TabButton
            label={isMobile ? 'Press' : 'Press Releases'}
            bg={tab === 'pr' ? '#f7f7f7' : 'transparent'}
            color={tab === 'pr' ? '#087dc5' : '#888'}
            fontWeight={tab === 'pr' ? 700 : 500}
            onClick={() => setTab('pr')}
            width={{
              base: '100%',
              md: 'auto',
            }}
          />
          <TabButton
            label={isMobile ? 'News' : 'In the News'}
            bg={tab === 'news' ? '#f7f7f7' : 'transparent'}
            color={tab === 'news' ? '#087dc5' : '#888'}
            fontWeight={tab === 'news' ? 700 : 500}
            onClick={() => setTab('news')}
            width={{
              base: '100%',
              md: 'auto',
            }}
          />
        </Flex>
        {loading ? (
          <Loader />
        ) : pressReleases && tab === 'pr' ? (
          <Flex flexDir="column" pos="relative" zIndex={1}>
            {pressReleases.map((pressRelease: PressReleaseProps) => {
              return <PR pr={pressRelease} />;
            })}
          </Flex>
        ) : companyNews && tab === 'news' ? (
          <Flex flexDir="column" pos="relative" zIndex={1}>
            {companyNews.map((news: CompanyNewsProps) => {
              return <News news={news} />;
            })}
          </Flex>
        ) : (
          <Flex>We had a problem.</Flex>
        )}
      </Container>
    </>
  );
};

export default Press;
