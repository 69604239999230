import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PRESS } from '../../constants/urls';
import { objectEmpty } from '../../utils/objectEmpty';
import Loading from '../Loading';
import { releaseFetch } from '../Sanity/Data';
import { PressReleaseProps } from '../Sanity/Model';
import Detail from './ReleaseDetail';

const Release = () => {
  const { pr } = useParams<{ pr: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [release, setRelease] = useState<PressReleaseProps | null>(null);
  const fetch = async () => {
    setLoading(true);
    const data: PressReleaseProps | null = await releaseFetch(pr);
    if (data && objectEmpty(data)) {
      window.location.href = PRESS;
      return;
    }
    setLoading(false);
    setRelease(data);
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : release ? (
        <Detail release={release} full />
      ) : (
        <Flex>Something went wrong</Flex>
      )}
    </>
  );
};

export default Release;
