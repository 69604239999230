import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Footer from '../../components/Footer';
import Nav from '../../components/Nav/Nav';
import { PRESS } from '../../constants/urls';
import useDevice, { DevicesEnum } from '../../hooks/useDevice';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
  const device = useDevice();

  const { pathname } = useLocation();

  return (
    <Box minH="100vh">
      {device === DevicesEnum.mobile ? (
        <>
          <Nav />
          <Flex justifyContent="center" flexGrow={1}>
            <Container>{children}</Container>
          </Flex>
          <Footer />
        </>
      ) : (
        <>
          <Nav />
          <Container maxW={pathname === PRESS ? '100%' : 'container.xl'} minH="100vh">
            <Flex
              justifyContent="center"
              flexGrow={1}
              flexDir={pathname === PRESS ? 'column' : 'row'}
            >
              {children}
            </Flex>
            <Footer />
          </Container>
        </>
      )}
    </Box>
  );
};

export default Main;
