/* eslint-disable react/jsx-pascal-case */
import { Box, Container, Flex, Image, Text } from '@chakra-ui/react';

import { BigGameGiveaway2025Square } from '../../assets/Images';
import Footer from '../../components/Footer';
import LicensePlateOrVinInput_LPSimple from '../../components/LicensePlateOrVinInput_LPSimple';
import Nav from '../../components/Nav/Nav';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';

const SuperBowl2025 = () => {
  return (
    <>
      <Box
        minH="100%"
        bg="linear-gradient(180deg, rgba(186,244,255,1) 0%, rgba(186,244,255,0.5) 5%, rgba(255,255,255,1) 50%, rgba(186,244,255,0.5) 95%, rgba(186,244,255,1) 100%)"
      >
        <Box>
          <Nav />
        </Box>
        <Container maxW="1200px" my={4} pb={16}>
          <Flex
            gap={8}
            flexDir={{
              base: 'column',
              md: 'row',
            }}
            justifyContent={{
              base: 'center',
            }}
            alignItems={{
              base: 'center',
              md: 'flex-start',
            }}
          >
            <Image
              src={BigGameGiveaway2025Square}
              w="340px"
              h="340px"
              borderRadius={12}
              border="1px solid #002d5d"
              p={2}
              background="transparent"
            />
            <Flex flexDir="column" gap={4}>
              <Title
                fontSize={{
                  base: 42,
                  md: 48,
                }}
                lineHeight={1}
                fontWeight={900}
                color="#000"
                textTransform="uppercase"
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                m={0}
                p={0}
              >
                Win 2 Tickets to the Big Game in New Orleans!
              </Title>
              <Subtitle m="0 !important" p={0} lineHeight={1.4} color="black">
                End Your Lease by January 31st & get entered to win 2 tickets to watch football's
                biggest game in person in New Orleans
                <Text as="br" />
                <Text as="span" fontSize={14}>
                  (terms and conditions below)
                </Text>
                .
              </Subtitle>
              <LicensePlateOrVinInput_LPSimple />
            </Flex>
          </Flex>
          <Flex
            pt={8}
            mt={8}
            mb={16}
            borderTopWidth={1}
            flexDir="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            textAlign="left"
          >
            <Title color="black" fontSize={20}>
              Big Game Giveaway Terms & Conditions{' '}
            </Title>
            <Text py={4} fontSize={12}>
              Effective Date: January 9, 2025{' '}
            </Text>

            <Flex flexDir="column" gap={4}>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                1. ELIGIBILITY{' '}
              </Text>
              <Text>
                This sweepstakes is open to legal residents of the United States who are 18 years or
                older at the time of entry. Employees, officers, directors, and agents of Lease End,
                as well as their immediate families (spouse, parents, siblings, and children) or
                household members, are not eligible to participate.{' '}
              </Text>

              <Text textAlign="left" fontWeight={700} fontSize={16}>
                2. SWEEPSTAKES PERIOD{' '}
              </Text>
              <Text>
                The sweepstakes begins at 12:00 AM (EST) on January 9, 2025, and ends at 11:59 PM
                (EST) on January 31, 2025. Entries submitted outside of this time frame will not be
                eligible.{' '}
              </Text>

              <Text textAlign="left" fontWeight={700} fontSize={16}>
                3. HOW TO ENTER{' '}
              </Text>
              <Text>
                To qualify for the Big Game Giveaway promotion, applicants must secure a new loan.
                This offer does not apply to the refinancing of existing loans. Loan applications
                must be submitted and loan documents signed no later than 01/31/2025 to be eligible
                for the promotional rate. Only one (1) entry per participant is allowed. Multiple
                entries or automated entries will result in disqualification.
              </Text>

              <Text textAlign="left" fontWeight={700} fontSize={16}>
                4. PRIZE{' '}
              </Text>
              <Text>
                One (1) winner will receive: Two (2) tickets to the Super Bowl in New Orleans,
                Louisiana, on February 11, 2025. One (1) night of double-occupancy hotel
                accommodations in New Orleans. The total approximate retail value (ARV) of the prize
                is $15,000. The prize is non-transferable and cannot be exchanged for cash or other
                alternatives.
              </Text>

              <Text textAlign="left" fontWeight={700} fontSize={16}>
                5. WINNER SELECTION
              </Text>
              <Text>
                The winner will be selected randomly from all eligible entries on February 3, 2025.
                Lease End will notify the winner via the contact information provided during the
                credit application process. The winner must respond within 48 hours of notification
                to claim the prize. If the winner does not respond or is deemed ineligible, an
                alternate winner will be chosen.
              </Text>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                6. CONDITIONS OF PARTICIPATION
              </Text>
              <Text>
                By entering the sweepstakes, participants agree to: Abide by these Terms &
                Conditions and the decisions of Lease End, which are final and binding. Allow Lease
                End to use their name, likeness, and city/state of residence for promotional
                purposes without additional compensation, unless prohibited by law. The potential
                winner may be required to: Complete, sign, and return an affidavit of eligibility
                and liability/publicity release within 48 hours of notification. Undergo a
                background check to ensure they do not bring disrepute to Lease End. Failure to
                comply will result in forfeiture of the prize and selection of an alternate winner.
              </Text>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                7. GENERAL CONDITIONS
              </Text>
              <Text>
                Lease End reserves the right to cancel, suspend, or modify the sweepstakes if fraud,
                technical failures, or any other factor beyond its control compromises the integrity
                of the promotion. Lease End is not responsible for any technical errors, lost
                entries, or incomplete submissions. By entering, participants release Lease End, its
                affiliates, employees, and agents from any liability related to the sweepstakes or
                use of the prize.
              </Text>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                8. RELEASE OF LIABILITY
              </Text>
              <Text>
                By participating, entrants agree to release Lease End and its affiliates, employees,
                and agents from any claims or damages resulting from: Participation in the
                sweepstakes. Acceptance or use of the prize, including travel, accommodations, or
                attendance at the event. Lease End is not responsible for: Lost, late, misdirected,
                or incomplete entries. Technical issues such as system failures or interruptions.
                Acts of God, natural disasters, or other events that may disrupt the sweepstakes.
              </Text>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                9. GOVERNING LAW
              </Text>
              <Text>
                This sweepstakes is governed by the laws of the State of Idaho, without regard to
                its conflict of law principles. Any disputes shall be resolved by binding
                arbitration in the State of Idaho.
              </Text>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                10. PRIVACY POLICY
              </Text>
              <Text>
                The information you submit in connection with this sweepstakes may be used by Lease
                End for administration and fulfillment purposes and in accordance with Lease End’s
                Privacy Policy.
              </Text>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                11. COPYRIGHT & PUBLICITY RELEASE
              </Text>
              <Text>
                By entering, participants grant Lease End an exclusive, worldwide, royalty-free
                license to use their name, likeness, and entry for promotional purposes. This
                includes, but is not limited to, advertising, publicity, and marketing, in any
                medium now known or hereafter developed.
              </Text>
              <Text textAlign="left" fontWeight={700} fontSize={16}>
                12. CONTACT INFORMATION
              </Text>
              <Text>
                For questions regarding the sweepstakes, please contact: Lease End Customer Support
                Phone: (844) 902-2842 Email: support@leaseend.com
              </Text>
            </Flex>
          </Flex>
          <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
        </Container>
      </Box>
    </>
  );
};

export default SuperBowl2025;
