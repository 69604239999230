import { Box, Image, useMediaQuery } from '@chakra-ui/react';

import { CivicGuy, HomePageSimpleBackgroundWideSVG } from '../../../assets/Images';
import useCustomBreakpointCallback from '../../../hooks/useCustomBreakpointCallback';

const UTMCarDudeBgImage = () => {
  const bpv = useCustomBreakpointCallback();
  const [heightUnder500] = useMediaQuery('(max-height: 500px)');
  const [heightUnder700] = useMediaQuery('(max-height: 700px)');
  const [heightUnder1050] = useMediaQuery('(max-height: 1050px)');
  const [heightOver1000] = useMediaQuery('(min-height: 1000px)');

  return (
    <Box position="absolute" h="100vh" zIndex="-2" w="100%" top={0}>
      <Image
        src={CivicGuy}
        alt="Car Dude"
        position="absolute"
        top={{
          base: bpv({
            350: heightUnder700 ? '81%' : '50%',
            450: '48%',
            550: '48%',
            625: heightUnder500 ? '90%' : '45%',
            800: heightOver1000 ? '43%' : '55%',
            1000: heightUnder1050 ? '54%' : '42%',
          }),
        }}
        right={{
          base: 'calc(50% - 425px)',
          md: 'calc(50% - 350px)',
          sm: '0',
        }}
        w={{
          base: '850px',
          md: '700px',
          sm: '100%',
        }}
        transition="opacity 0.5s ease-in-out"
        overflow="hidden"
        m="0 auto"
      />
      <Image
        src={HomePageSimpleBackgroundWideSVG}
        alt="Scenic Background"
        h="1080px"
        objectFit="cover"
        objectPosition={{ base: 'center -35px' }}
        zIndex="-1"
        m="0 auto"
      />
    </Box>
  );
};

export default UTMCarDudeBgImage;
