import { Image, Text, VStack } from '@chakra-ui/react';

import { TopTenCarLoansLogo } from '../../../assets/Images';

const TopTenCarLoansPartnershipBadge = () => {
  return (
    <VStack align="flex-start" spacing={1}>
      <Text color="black" fontSize="12px">
        In Partnership With
      </Text>
      <Image
        src={TopTenCarLoansLogo}
        alt="To Ten Car Loans"
        h={{ base: '82px', md: '54px' }}
        w={{ base: '240px', md: '160px' }}
        mr="auto"
      />
    </VStack>
  );
};

export default TopTenCarLoansPartnershipBadge;
