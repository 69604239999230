import { Button, Flex, Link, Text } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import Title from '../../components/Title';
import { PRESS } from '../../constants/urls';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { PressReleaseProps, ptBlogComponents } from '../Sanity/Model';
import PRImageComponentImage from '../Sanity/Press/PRImageComponent';

interface DetailProps {
  full?: boolean;
  release: PressReleaseProps;
}

const Detail = ({ full, release }: DetailProps) => {
  const history = useHistory();
  const jump = (slug: string) => {
    history.push(`${PRESS}/press-releases/${slug}`);
  };
  return (
    <Flex
      flexDir={{
        base: 'column',
        md: 'row',
      }}
      alignItems="flex-start"
      gap={16}
      p={4}
      py={8}
      borderBottomWidth={1}
    >
      {release.mainImage && !full && (
        <Flex
          width={{
            base: '100%',
            md: '25%',
          }}
          maxW={!full ? '300px' : '100%'}
          minW={!full ? '200px' : '25%%'}
          justifyContent="center"
          mx="auto"
          cursor="pointer"
          onClick={() => jump(release.slug.current)}
        >
          <PRImageComponentImage value={release.mainImage} width={250} />
        </Flex>
      )}
      <Flex
        flexDir="column"
        maxWidth={{
          base: '1024px',
        }}
        mx="auto"
        gap={8}
      >
        {full && (
          <Link href={PRESS} title="Lease End Press Resources">
            Back
          </Link>
        )}
        <Text fontSize={16} color="#444" fontWeight={600}>
          {moment(new Date(release?.publishDate || release?.updateDate)).format('MMMM Do, YYYY')}
        </Text>
        <Title
          textAlign="left"
          fontSize={32}
          fontWeight={700}
          m={0}
          cursor="pointer"
          onClick={() => jump(release.slug.current)}
        >
          {release?.title}
        </Title>
        {full ? (
          <Flex justifyContent="flex-start" flexDir="column" fontSize={15} alignItems="flex-start">
            {release.mainImage && full && (
              <Flex
                width={{
                  base: '100%',
                  md: '50%',
                }}
                mx="auto"
                justifyContent="center"
                mb={8}
              >
                <BlogImageComponent value={release.mainImage} isInline={false} />
              </Flex>
            )}
            <PortableText components={ptBlogComponents} value={release.body} />
          </Flex>
        ) : (
          <Flex justifyContent="flex-start">
            <Button fontWeight={600} onClick={() => jump(release?.slug.current)}>
              Read More
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
export default Detail;
