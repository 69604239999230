export const MAIN_NUMBER = '(844) 902-2842';

export const TITLES_EMAIL = 'titles@leaseend.com';

export const CHECK_YOUR_PHONE_MESSAGE = (hasCobuyer: boolean) =>
  `${
    hasCobuyer ? 'You and your co-buyer' : 'You'
  } should receive a text from us in a few moments. Reply with the photos requested on this page. Once you’ve${
    hasCobuyer ? ' both' : ''
  } sent us your pictures, click the button below to let us know you’re ready to continue.`;

export const CONTACT_US_EMAIL = 'rebecca.graham@leaseend.com';
