import { Button, Flex, Link } from '@chakra-ui/react';

import Title from '../../components/Title';
import { PRESS } from '../../constants/urls';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { CompanyNewsProps } from '../Sanity/Model';

interface NewsDetailProps {
  full?: boolean;
  companyNews: CompanyNewsProps;
}

const NewsDetail = ({ full, companyNews }: NewsDetailProps) => {
  return (
    <Flex
      flexDir={{
        base: 'column',
        md: 'row',
      }}
      alignItems="center"
      gap={16}
      p={4}
      py={8}
      borderBottomWidth={1}
    >
      {companyNews.mainImage && (
        <Flex
          width={{
            base: '100%',
            md: '25%',
          }}
        >
          <Link href={companyNews.url} color="inherit" target="_blank">
            <BlogImageComponent value={companyNews.mainImage} isInline={false} />
          </Link>
        </Flex>
      )}
      <Flex
        flexDir="column"
        width={{
          base: '100%',
          md: companyNews.mainImage ? '75%' : '100%',
        }}
        gap={8}
      >
        {full && (
          <Link href={PRESS} title="Lease End Press Resources">
            Back
          </Link>
        )}
        <Title textAlign="left" fontSize={32} fontWeight={700} m={0}>
          <Link href={companyNews.url} color="inherit" target="_blank">
            {companyNews?.title}
          </Link>
        </Title>
        <Flex justifyContent="flex-start">
          <Button fontWeight={600}>
            <Link
              href={companyNews.url}
              target="_blank"
              color="inherit"
              _hover={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              Read More
            </Link>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default NewsDetail;
