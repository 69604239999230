import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LDFlags } from '../constants/experiments';
import { VIP_CONSULTATION } from '../constants/urls';
import useFlag from './useFlag';

const useVIPConsult = () => {
  const { pathname } = useLocation();
  const [vipConsult, setVipConsult] = useState<boolean>(false);
  const EXP97 = useFlag(LDFlags.EXP97);
  useEffect(() => {
    setVipConsult(pathname === VIP_CONSULTATION && EXP97);
  }, []);
  return vipConsult;
};
export default useVIPConsult;
