/* eslint-disable react/destructuring-assignment */
import { SanityImageSource } from '@sanity/asset-utils';
import urlBuilder from '@sanity/image-url';

import client from '../../../client';

interface PRImageComponentImageProps {
  value: SanityImageSource & { alt?: string; width?: number; alignment?: string };
  width?: number;
}

export const PRImageComponentImage = ({ width = 100, value }: PRImageComponentImageProps) => {
  return (
    <img
      src={urlBuilder(client).image(value).width(width).url()}
      alt={value.alt || 'Lease End'}
      loading="lazy"
      style={{
        display: 'block',
        width: `${width}px`,
        borderRadius: 8,
        padding: 4,
        border: '1px solid #aaa',
      }}
    />
  );
};

export default PRImageComponentImage;
