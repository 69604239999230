export enum Images {
  FAQBanner = 'faq.svg',

  // Press
  FactSheet = 'FactSheet.svg',
  GreenCircleMountain = 'GreenCircleMountain.svg',
  ManWithLoudSpeaker = 'ManWithLoudSpeaker.svg',
  OurTeam = 'OurTeam.svg',
  DownloadArrowBlue = 'download_arrow_blue.svg',

  // About us
  AboutUsLeaseOwner = 'about-us/lease_owner.png',

  // About us - bios
  AboutUsBrandonWilliams = 'about-us/Brandon_Williams.svg',
  AboutUsZanderCook = 'about-us/Zander_Cook.svg',
  AboutUsDaveWilliams = 'about-us/Dave_Williams.svg',
  AboutUsJeremyToner = 'about-us/Jeremy_Toner.svg',
  AboutUsChipCook = 'Chip.svg',

  //
  PointingPhoneGuy = 'pointing_phone_guy.svg',
  OkGestureGuy = 'ok_gesture_guy.svg',

  // Contact Us
  Idaho = 'Idaho.svg',

  // Home
  Arrow = 'arrow.svg',
  BestDecision = 'bestdecision.webp',
  PaymentCalculatorIcon = 'payment-calculator-icon.svg',
  PaymentCalculatorIconWhite = 'payment-calculator-icon-white.svg',
  Google = 'google.svg',
  GoogleReviews = 'google-reviews.svg',
  FiveStars = '5-stars.svg',
  Quote = 'quote.svg',
  HomePageSimpleBackgroundWideSVG = 'home-page-simple-bg.svg',
  BlueCar = 'BlueCar.svg',
  CarPhoneLady = 'CarPhoneLady.svg',
  DocumentSignatures = 'DocumentSignatures.svg',
  NoDealership = 'NoDealership.svg',
  OverMileage = 'odometer_red.svg',
  PhoneGuy = 'PhoneGuy.svg',
  PiggyBank = 'PiggyBank.svg',
  ResidualValue = 'residualvalue.webp',
  SleepyCouch = 'SleepyCouch.svg',
  SparkAward = 'SparkAward.png',
  StandardFees = 'standardfees.webp',
  Tags = 'Tags.svg',
  VehicleDamage = 'VehicleDamage.svg',
  TrustPilotIcon = 'trust-pilot-icon.svg',
  TrustPilotIconWhite = 'trust-pilot-icon-white.png',
  Inc5000 = 'inc_5000.svg',
  CarDude2 = 'car_dude2.svg',
  CivicGuy = 'civic_guy.svg',
  NSGGuy = 'NSG-guy.svg',
  CivicGuy__EXP12 = 'civic__exp12.svg',
  RefiCar = 'refi_car.svg',
  BestMoneyLogo = 'best_money_logo.svg',
  ConsumerVoiceLogo = 'consumer-voice-logo.svg',
  TopTenCarLoansLogo = 'top-ten-car-loans.svg',
  HappyHug = 'happy-hug.png',
  TubeMan = 'tube-man.png',
  MacGS = 'mac-gs.png',
  LearnLogo = 'learn-logo.png',
  Sleek = 'sleek.jpg ',
  CareerHero = 'careers-hero.png',
  CareerLocations = 'careers-locations.svg',
  LEValuesDD = 'le-values-dd.svg',
  LEValuesGSD = 'le-values-gsd.svg',
  LEValuesLEAN = 'le-values-lean.svg',
  LEValuesP1 = 'le-values-p1.svg',
  LEValuesUU = 'le-values-uu.svg',

  // How It Works
  LeaseEndGuyGivingMoney = 'lease-end-guy-giving-money.svg',
  LeaseEndCarHauler = 'lease-end-car-hauler.svg',
  LeaseEndGuyHuggingCar = 'lease-end-guy-hugging-car.svg',
  GreenArrow = 'green-arrow.svg',

  // Lienholder Verification
  MagnifyingGlass = 'magnifying_glass.svg',

  // Mileage
  Odometer = 'odometer.svg',

  // Nav
  Logo = 'lease-end-logo.png',
  LogoWhite = 'lease-end-logo-white.png',
  SmallLogo = 'small-lease-end-logo.png',
  SmallLogoWhite = 'small-lease-end-logo-white.png',
  RefiLogo = 'refi-logo.svg',

  // VSC Selection
  AllyColor = 'lienholders/Finance_Logos_Ally_Color.png',

  // Reviews
  BBB = 'bbb_logo.svg',
  FacebookLogo = 'f_logo_RGB-Blue_250.png',

  // Unlock Your Equity
  GreenHandKey = 'greenHandKey.svg',
  GreenTag = 'greenTag.svg',

  // Health Tracker
  HealthTracker = 'HealthTracker.svg',
  CarEKG = 'car-ekg.svg',

  // 404 Page
  Background404 = '404Page_Background.svg',
  Foreground404 = '404Page_Foreground.svg',

  // Lease Peace
  AntiBot = 'AntiBot.png',
  Dollaritas = 'Dollaritas.svg',
  GreenHandKeyWithOutline = 'greenHandKeyWithOutline.png',
  LeasePeaceBackground = 'LeasePeaceBackground.svg',
  LeasePeaceMeditator = 'LeasePeaceMeditator.svg',
  LeasePeaceSymbol = 'LeasePeaceSymbol.svg',
  LeasePeaceVan = 'LeasePeaceVan.svg',
  OdometerPointer = 'OdometerPointer.png',
  ResultsCheck = 'ResultsCheck.svg',

  // Valentine Banner
  BeMyCarHeart = 'be-my-car-heart.svg',
  DriveMeWildHeart = 'drive-me-wild-heart.svg',
  WinHeart = 'win-heart.svg',
  ValentineGuy = 'valentine-guy.svg',

  // July 4 100
  July4100FW1 = 'july4100-fw-1.svg',
  July4100FW2 = 'july4100-fw-2.svg',
  July4100Jump = 'july4100-jump.svg',

  // bank logos
  AllyBankLogo = 'banks/ally_logo.png',
  CapitalOneBankLogo = 'banks/capital_one_logo.svg',
  FirstInterstateBankLogo = 'banks/first_interstate_bank_logo.png',
  MacuBankLogo = 'banks/macu_logo.png',
  TdBankLogo = 'banks/td_bank_logo.png',
  TdBankLogoCrop = 'banks/td_bank_logo_crop.png',
  ChaseBankLogo = 'banks/chase_bank_logo.png',

  // endorsements
  EndorsementsLogo = 'endorsements-logo.svg',
  EndorsementsLogoSimple = 'endorsements-logo-simple.svg',
  Endorsements12Min = 'endorsements-12min.svg',
  Endorsements17x = 'endorsements-17x.svg',
  Endorsements86perc = 'endorsements-86perc.svg',
  Endorsements84perc = 'endorsements-84perc.svg',
  Endorsements76perc = 'endorsements-76perc.svg',
  EndorsementsXperc = 'endorsements-Xperc.svg',
  EndorsementsNoBull = 'endorsements-no-bull.svg',
  EndorsementsField = 'endorsements-field.jpg',
  EndorsementsIdaho = 'endorsements-idaho.svg',
  EndorsementsBankLoop = 'bank-logo-loop.gif',
  EndorsementsNewVehicle = 'endorsements-new-vehicle.svg',
  SingleQuote = 'single-quote.svg',
  PrequalOrnament = 'prequal-ornament.svg',
  PrequalCar = 'prequal-car.svg',
  PrequalSearch = 'cloud_search.gif',
  JJLPSMP = 'jj-lp-smp.png',
  JJLPSMPFlip = 'jj-lp-smp-flip.png',
  JJLPSMPF = 'jj-lp-smp-f.png',
  JJLPSMPFFlip = 'jj-lp-smp-f-flip.png',
  PinkPointer = 'pink-dude-pointer.svg',
  LoanApproved = 'loan-approved.svg',
  CarSold = 'car-sold.svg',
  EquityLeasedCar = 'equity-and-your-leased-car.png',
  BestBuyoutRates = 'best-lease-buyout-loan-rates.png',
  DkLpBg = 'dk-lp-bg.png',
  DkLpBgSub = 'dk-lp-bg-sub.png',
  Endorsement89Chart = 'endorsements/Endorsement-89-Chart.svg',
  LeanInPoint = 'lean-in-point.svg',
  TeslaLogo = 'Logo-Tesla.png',
  AsSeenOnTv = 'asotv.png',
  ETMCover = 'ETM_cover.png',
  ETMPanel1 = 'etm-panel-1.png',
  ETMPanel2 = 'etm-panel-2.png',
  ETMPanel3 = 'etm-panel-3.png',
  BigGameGiveaway2025 = 'big-game-giveaway-25.png',
  BigGameGiveaway2025Square = 'BigGame_Giveaway-sq.png',
  ExclusiveTag = 'exclusive-tag.png',
  BigGameJump = 'BigGame_Jump.png',
  VipConsult = 'vip-consult.svg',
  OrangeHeadset = 'orange-headset.svg',
  VipNoDealer = 'vip-no-dealer.svg',
}

export const getImage = (i: string): string => `/leaseend-com-images/${i}`;

export const FAQBanner = getImage(Images.FAQBanner);
export const FactSheet = getImage(Images.FactSheet);
export const GreenCircleMountain = getImage(Images.GreenCircleMountain);
export const ManWithLoudSpeaker = getImage(Images.ManWithLoudSpeaker);
export const OurTeam = getImage(Images.OurTeam);
export const DownloadArrowBlue = getImage(Images.DownloadArrowBlue);
export const AboutUsLeaseOwner = getImage(Images.AboutUsLeaseOwner);
export const AboutUsBrandonWilliams = getImage(Images.AboutUsBrandonWilliams);
export const AboutUsZanderCook = getImage(Images.AboutUsZanderCook);
export const AboutUsDaveWilliams = getImage(Images.AboutUsDaveWilliams);
export const AboutUsJeremyToner = getImage(Images.AboutUsJeremyToner);
export const AboutUsChipCook = getImage(Images.AboutUsChipCook);
export const Arrow = getImage(Images.Arrow);
export const BBBLogo = getImage(Images.BBB);
export const BestDecision = getImage(Images.BestDecision);
export const BlueCar = getImage(Images.BlueCar);
export const GoogleLogo = getImage(Images.Google);
export const GoogleReviews = getImage(Images.GoogleReviews);
export const BestMoneyLogo = getImage(Images.BestMoneyLogo);
export const ConsumerVoiceLogo = getImage(Images.ConsumerVoiceLogo);
export const TopTenCarLoansLogo = getImage(Images.TopTenCarLoansLogo);
export const HappyHug = getImage(Images.HappyHug);
export const TubeMan = getImage(Images.TubeMan);
export const MacGs = getImage(Images.MacGS);
export const LearnLogo = getImage(Images.LearnLogo);
export const Sleek = getImage(Images.Sleek);
export const CareerHero = getImage(Images.CareerHero);
export const CareerLocations = getImage(Images.CareerLocations);
export const LEValuesDD = getImage(Images.LEValuesDD);
export const LEValuesGSD = getImage(Images.LEValuesGSD);
export const LEValuesLEAN = getImage(Images.LEValuesLEAN);
export const LEValuesP1 = getImage(Images.LEValuesP1);
export const LEValuesUU = getImage(Images.LEValuesUU);
export const CarDude2 = getImage(Images.CarDude2);
export const CivicGuy = getImage(Images.CivicGuy);
export const NSGGuy = getImage(Images.NSGGuy);
export const CivicGuy__EXP12 = getImage(Images.CivicGuy__EXP12);
export const RefiCar = getImage(Images.RefiCar);
export const CarPhoneLady = getImage(Images.CarPhoneLady);
export const DocumentSignatures = getImage(Images.DocumentSignatures);
export const FacebookLogo = getImage(Images.FacebookLogo);
export const FiveStars = getImage(Images.FiveStars);
export const GreenHandKey = getImage(Images.GreenHandKey);
export const GreenTag = getImage(Images.GreenTag);
export const HomePageSimpleBackgroundWideSVG = getImage(Images.HomePageSimpleBackgroundWideSVG);
export const LeaseEndGuyGivingMoney = getImage(Images.LeaseEndGuyGivingMoney);
export const LeaseEndCarHauler = getImage(Images.LeaseEndCarHauler);
export const LeaseEndGuyHuggingCar = getImage(Images.LeaseEndGuyHuggingCar);
export const GreenArrow = getImage(Images.GreenArrow);
export const Idaho = getImage(Images.Idaho);
export const Logo = getImage(Images.Logo);
export const LogoWhite = getImage(Images.LogoWhite);
export const SmallLogo = getImage(Images.SmallLogo);
export const SmallLogoWhite = getImage(Images.SmallLogoWhite);
export const RefiLogo = getImage(Images.RefiLogo);
export const MagnifyingGlass = getImage(Images.MagnifyingGlass);
export const NoDealership = getImage(Images.NoDealership);
export const Odometer = getImage(Images.Odometer);
export const OkGestureGuy = getImage(Images.OkGestureGuy);
export const OverMileage = getImage(Images.OverMileage);
export const PaymentCalculatorIcon = getImage(Images.PaymentCalculatorIcon);
export const PaymentCalculatorIconWhite = getImage(Images.PaymentCalculatorIconWhite);
export const PhoneGuy = getImage(Images.PhoneGuy);
export const PiggyBank = getImage(Images.PiggyBank);
export const PointingPhoneGuy = getImage(Images.PointingPhoneGuy);
export const Quote = getImage(Images.Quote);
export const ResidualValue = getImage(Images.ResidualValue);
export const SleepyCouch = getImage(Images.SleepyCouch);
export const SparkAward = getImage(Images.SparkAward);
export const StandardFees = getImage(Images.StandardFees);
export const Tags = getImage(Images.Tags);
export const TrustPilotIcon = getImage(Images.TrustPilotIcon);
export const TrustPilotIconWhite = getImage(Images.TrustPilotIconWhite);
export const VehicleDamage = getImage(Images.VehicleDamage);
export const Inc5000 = getImage(Images.Inc5000);
export const CarEKG = getImage(Images.CarEKG);
export const HealthTracker = getImage(Images.HealthTracker);
export const Background404 = getImage(Images.Background404);
export const Foreground404 = getImage(Images.Foreground404);
export const AntiBot = getImage(Images.AntiBot);
export const Dollaritas = getImage(Images.Dollaritas);
export const GreenHandKeyWithOutline = getImage(Images.GreenHandKeyWithOutline);
export const LeasePeaceBackground = getImage(Images.LeasePeaceBackground);
export const LeasePeaceMeditator = getImage(Images.LeasePeaceMeditator);
export const LeasePeaceSymbol = getImage(Images.LeasePeaceSymbol);
export const LeasePeaceVan = getImage(Images.LeasePeaceVan);
export const OdometerPointer = getImage(Images.OdometerPointer);
export const ResultsCheck = getImage(Images.ResultsCheck);

export const BeMyCarHeart = getImage(Images.BeMyCarHeart);
export const DriveMeWildHeart = getImage(Images.DriveMeWildHeart);
export const WinHeart = getImage(Images.WinHeart);
export const ValentineGuy = getImage(Images.ValentineGuy);

export const July4100FW1 = getImage(Images.July4100FW1);
export const July4100FW2 = getImage(Images.July4100FW2);
export const July4100Jump = getImage(Images.July4100Jump);

export const AllyBankLogo = getImage(Images.AllyBankLogo);
export const CapitalOneBankLogo = getImage(Images.CapitalOneBankLogo);
export const FirstInterstateBankLogo = getImage(Images.FirstInterstateBankLogo);
export const MacuBankLogo = getImage(Images.MacuBankLogo);
export const TdBankLogo = getImage(Images.TdBankLogo);
export const TdBankLogoCrop = getImage(Images.TdBankLogoCrop);
export const ChaseBankLogo = getImage(Images.ChaseBankLogo);

export const EndorsementsLogo = getImage(Images.EndorsementsLogo);
export const EndorsementsLogoSimple = getImage(Images.EndorsementsLogoSimple);
export const Endorsements12Min = getImage(Images.Endorsements12Min);
export const Endorsements17x = getImage(Images.Endorsements17x);
export const Endorsements86perc = getImage(Images.Endorsements86perc);
export const Endorsements84perc = getImage(Images.Endorsements84perc);
export const Endorsements76perc = getImage(Images.Endorsements76perc);
export const EndorsementsXperc = getImage(Images.EndorsementsXperc);
export const EndorsementsNoBull = getImage(Images.EndorsementsNoBull);
export const EndorsementsField = getImage(Images.EndorsementsField);
export const EndorsementsIdaho = getImage(Images.EndorsementsIdaho);
export const EndorsementsBankLoop = getImage(Images.EndorsementsBankLoop);
export const EndorsementsNewVehicle = getImage(Images.EndorsementsNewVehicle);

export const SingleQuote = getImage(Images.SingleQuote);
export const PrequalOrnament = getImage(Images.PrequalOrnament);
export const PrequalCar = getImage(Images.PrequalCar);
export const PrequalSearch = getImage(Images.PrequalSearch);
export const JJLPSMP = getImage(Images.JJLPSMP);
export const JJLPSMPFlip = getImage(Images.JJLPSMPFlip);
export const JJLPSMPF = getImage(Images.JJLPSMPF);
export const JJLPSMPFFlip = getImage(Images.JJLPSMPFFlip);
export const PinkPointer = getImage(Images.PinkPointer);
export const LoanApproved = getImage(Images.LoanApproved);
export const CarSold = getImage(Images.CarSold);
export const EquityLeasedCar = getImage(Images.EquityLeasedCar);
export const BestBuyoutRates = getImage(Images.BestBuyoutRates);
export const DkLpBg = getImage(Images.DkLpBg);
export const DkLpBgSub = getImage(Images.DkLpBgSub);
export const Endorsement89Chart = getImage(Images.Endorsement89Chart);
export const LeanInPoint = getImage(Images.LeanInPoint);
export const TeslaLogo = getImage(Images.TeslaLogo);
export const AsSeenOnTv = getImage(Images.AsSeenOnTv);
export const ETMCover = getImage(Images.ETMCover);
export const ETMPanel1 = getImage(Images.ETMPanel1);
export const ETMPanel2 = getImage(Images.ETMPanel2);
export const ETMPanel3 = getImage(Images.ETMPanel3);
export const BigGameGiveaway2025 = getImage(Images.BigGameGiveaway2025);
export const BigGameGiveaway2025Square = getImage(Images.BigGameGiveaway2025Square);
export const ExclusiveTag = getImage(Images.ExclusiveTag);
export const BigGameJump = getImage(Images.BigGameJump);
export const VipConsult = getImage(Images.VipConsult);
export const OrangeHeadset = getImage(Images.OrangeHeadset);
export const VipNoDealer = getImage(Images.VipNoDealer);
