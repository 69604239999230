import { PressReleaseProps } from '../Sanity/Model';
import Detail from './ReleaseDetail';

interface PRProps {
  pr: PressReleaseProps;
}

const PR = ({ pr }: PRProps) => {
  return <Detail release={pr} full={false} />;
};

export default PR;
