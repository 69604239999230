import { useCallback } from 'react';

import useWindowSize from './useWindowSize';

interface CustomBreakpoints {
  [key: number]: string;
}

const useCustomBreakpointCallback = () => {
  const { windowWidth, windowHeight } = useWindowSize();

  const callback = useCallback(
    ({ ...breakpoints }: CustomBreakpoints, verticalBreakpoints = false): string => {
      return Object.entries(breakpoints).reduce((prev, [key, value], index) => {
        if (Number(key) < (verticalBreakpoints ? windowHeight : windowWidth) || index === 0)
          return value;
        return prev;
      }, '');
    },
    [windowWidth, windowHeight],
  );

  return callback;
};

export default useCustomBreakpointCallback;
