import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PRESS } from '../../constants/urls';
import { objectEmpty } from '../../utils/objectEmpty';
import Loading from '../Loading';
import { newsFetch } from '../Sanity/Data';
import { CompanyNewsProps } from '../Sanity/Model';
import NewsDetail from './NewsDetail';

const News = () => {
  const { news } = useParams<{ news: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [companyNews, setCompanyNews] = useState<CompanyNewsProps | null>(null);
  const fetch = async () => {
    setLoading(true);
    const data: CompanyNewsProps | null = await newsFetch(news);
    if (data && objectEmpty(data)) {
      window.location.href = PRESS;
      return;
    }
    setLoading(false);
    setCompanyNews(data);
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : companyNews ? (
        <NewsDetail companyNews={companyNews} full />
      ) : (
        <Flex>Something went wrong</Flex>
      )}
    </>
  );
};

export default News;
