import { Divider, Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt';
import { MdLocationOn } from '@react-icons/all-files/md/MdLocationOn';
import { Form, useFormikContext } from 'formik';
import { MdOutlineCreditScore } from 'react-icons/md/index';
import { RiMoneyDollarBoxFill } from 'react-icons/ri';

import { LDFlags } from '../../constants/experiments';
import { PAYMENT_CALCULATOR_VARIATIONS } from '../../constants/paymentCalculator';
import { useFlag } from '../../hooks';
import {
  creditScores,
  getCreditScoreGrade,
  getCreditScoreRangeText,
  termsArr,
} from '../../utils/paymentCalculator';
import SliderOption from '../SliderOption';
import Input from '../formComponents/Input';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { FormFields } from './MonthlyPaymentCalculator';

interface Props {
  optOutFlag?: boolean;
}

const CalculatorForm = ({ optOutFlag = false }: Props) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<FormFields>();
  const paymentCalculatorVariation = useFlag(LDFlags.PAYMENT_CALCULATOR_VARIATION);

  return (
    <Form>
      <Flex
        justifyContent="space-evenly"
        mx="auto"
        flexWrap={{ sm: 'wrap', lg: 'nowrap' }}
        maxW={{ sm: '400px', md: 'none' }}
        gap={3}
      >
        <SliderOption
          title="Lease payoff"
          subtitle="A guess is fine right now!"
          icon={RiMoneyDollarBoxFill}
          min={1000}
          max={80000}
          step={100}
          isMoney
          name="vehicle_payoff"
          subtitleProps={{ minH: { lg: '35px' } }}
        />
        <SliderOption
          title="Credit score"
          subtitle={getCreditScoreGrade(values.credit_score)}
          icon={MdOutlineCreditScore}
          min={1}
          max={creditScores.length}
          minMark="300"
          maxMark="800+"
          name="credit_score"
          readOnly
          value={
            creditScores.findIndex(
              ({ min, max }) => values.credit_score >= min && values.credit_score <= max,
            ) + 1
          }
          customOnChange={(val) => {
            const score = creditScores[val - 1];
            setFieldValue('credit_score', score.max);
          }}
          inputValue={getCreditScoreRangeText(values.credit_score)}
          subtitleProps={{ minH: { lg: '35px' } }}
        />
        <SliderOption
          title="Down payment"
          subtitle="This isn't required!"
          icon={RiMoneyDollarBoxFill}
          min={0}
          max={4000}
          inputMax={values.vehicle_payoff}
          step={100}
          isMoney
          name="down_payment"
          subtitleProps={{ minH: { lg: '35px' } }}
        />
        <SliderOption
          title="Loan term"
          subtitle="You can change this later"
          icon={FaRegCalendarAlt}
          min={1}
          max={4}
          minMark="48 mos"
          maxMark="84 mos"
          name="term"
          readOnly
          value={termsArr.indexOf(values.term) + 1}
          inputValue={`${values.term} mos`}
          customOnChange={(val) => setFieldValue('term', termsArr[val - 1])}
          subtitleProps={{ minH: { lg: '35px' } }}
        />
      </Flex>
      <Divider w="100%" bg="gray" pt="0.5px" mt="6" />
      <Flex
        bg="leaseEndGreen"
        p={8}
        mt="6"
        rounded="md"
        borderWidth="1px"
        borderColor="gray"
        flexDirection={{ sm: 'column', md: 'row' }}
      >
        <VStack color="white" textAlign={{ sm: 'center', md: 'inherit' }}>
          <Text mr={{ sm: 0, md: 'auto' }} fontSize={22} fontWeight="bold">
            Ready for your estimate?
          </Text>
          {/* <Text fontSize={14} w="85%" alignSelf={{ sm: 'center', md: 'start' }} pb="5">
            With just 5 pieces of info <b>…BAM!…</b> we'll give you an estimate for your auto lease
            payoff and new monthly payment with Lease End, if you want to keep your leased vehicle.
          </Text> */}
          <Text
            fontSize={16}
            w="85%"
            alignSelf={{ sm: 'center', md: 'start' }}
            pb="5"
            lineHeight={1.3}
          >
            We’ll just need your zip code to factor any state taxes and fees into your quote.
            <Text as="br" />
            <Text as="br" />
            As a reminder, these are estimates. However, we’ve found that drivers often overestimate
            their predicted monthly payment. For a more accurate quote, fill out our application
            form or speak with one of our advisors at{' '}
            <Link
              color="white"
              textDecoration="underline"
              href="tel:8339881699"
              _hover={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              (833) 988-1699
            </Link>
            .
          </Text>
        </VStack>
        <VStack color="white" justifyContent="center">
          <Input
            placeholder="Zip"
            name="zip"
            mask="99999"
            icon={<Icon as={MdLocationOn} w={7} h={7} color="leaseEndGreen" mr={6} />}
            size="lg"
            maxW={{ base: optOutFlag ? '250px' : '180px', md: 'none' }}
            mt="auto"
            _errorMessage={{
              bg: 'red.100',
              p: '2.5px',
              borderRadius: '2.5px',
              ml: 0,
            }}
          />
          {optOutFlag && (
            <>
              {paymentCalculatorVariation !== PAYMENT_CALCULATOR_VARIATIONS.zipPhone && (
                <Input
                  placeholder="Email"
                  name="email"
                  size="lg"
                  maxW={{ base: '250px', md: 'none' }}
                  mt="auto"
                  _errorMessage={{
                    bg: 'red.100',
                    p: '2.5px',
                    borderRadius: '2.5px',
                    ml: 0,
                  }}
                />
              )}
              {paymentCalculatorVariation !== PAYMENT_CALCULATOR_VARIATIONS.zipEmail && (
                <Input
                  placeholder="Phone Number"
                  name="phone_number"
                  mask="(999) 999-9999"
                  size="lg"
                  maxW={{ base: '250px', md: 'none' }}
                  mt="auto"
                  _errorMessage={{
                    bg: 'red.100',
                    p: '2.5px',
                    borderRadius: '2.5px',
                    ml: 0,
                  }}
                />
              )}
            </>
          )}
          <PrimaryButton
            // This id is used in a LD metric, do not change it
            id="payment-calculator-submit-button"
            value="SEE YOUR PAYMENT"
            loading={isSubmitting}
            w={{ base: '180px', md: 'none' }}
            px={0}
            type="submit"
          />
          {optOutFlag && (
            <Text fontSize={12} w="90%" color="white" textAlign="center">
              By proceeding, you consent to receive communications from us.
            </Text>
          )}
        </VStack>
      </Flex>
      <Text color="taupeGray" textAlign="center" mt={3} fontSize={13}>
        These are estimates - once we're verify your info we'll give you an exact amount down to the
        penny.
      </Text>
    </Form>
  );
};

export default CalculatorForm;
