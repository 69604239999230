import { Flex, List, ListItem, Text } from '@chakra-ui/react';

import Title from '../../components/Title';

const Bottom = () => {
  return (
    <Flex
      flexDir="column"
      alignItems="flex-start"
      maxW="1252px"
      mx="auto"
      pt={8}
      mt={4}
      borderTopWidth={1}
    >
      <Title as="h2" fontSize={28}>
        Tell me more about lease buyouts.
      </Title>
      <Text color="leaseEndBlue">
        Leasing a car can be a great option for people who enjoy driving a new vehicle every few
        years without the long-term commitment of ownership. However, as your lease term nears its
        end, you're faced with a decision: return the car or buy it out. This is where a lease
        buyout comes into play.
      </Text>
      <Title as="h3" fontSize={18} fontWeight={700}>
        Lease buyouts, explained.
      </Title>
      <Text color="leaseEndBlue" mb={4}>
        A lease buyout is the process of purchasing your leased vehicle at the end of your lease
        term. This allows you to own the car outright, giving you the flexibility to keep it, sell
        it, or trade it in.
      </Text>
      <Text color="leaseEndBlue" mb={4}>
        Your buyout price is based on the residual value of your vehicle — or the estimated value of
        the vehicle at the end of the lease term — plus taxes and fees, interest, and additional
        coverage.
      </Text>
      <Text color="leaseEndBlue" mb={4}>
        With our cutting-edge technology, you can complete all paperwork from the comfort of your
        home: no dealership or DMV trip necessary.
      </Text>
      <Title as="h3" fontSize={18} fontWeight={700}>
        There are several benefits to lease buyouts.
      </Title>
      <List listStyleType="circle" ms={4} color="leaseEndBlue">
        <ListItem mb={4}>
          <Text as="span" fontWeight={700}>
            Familiarity:
          </Text>{' '}
          If you've grown attached to your leased vehicle and don't want to give it up, a lease
          buyout allows you to keep the car you love.{' '}
        </ListItem>
        <ListItem mb={4}>
          <Text as="span" fontWeight={700}>
            Ownership:
          </Text>{' '}
          Buying out your lease allows you to finance and eventually own the vehicle outright,
          giving you the freedom to keep it or sell it, benefitting from the equity you’ve built
          with your payments over time.{' '}
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight={700}>
            No Mileage Restrictions:
          </Text>{' '}
          When you own the vehicle, you are no longer bound by the mileage restrictions that come
          with a lease, allowing you to drive as much as you want without penalty.{' '}
        </ListItem>
      </List>
      <Title as="h3" fontSize={18} fontWeight={700}>
        We make calculating your buyout cost easy.
      </Title>
      <Text mb={4}>
        Calculating the buyout cost of a leased vehicle can be a bit complex, as it involves several
        factors such as the residual value, remaining payments, and any applicable fees.
      </Text>
      <Text mb={4}>
        Our lease buyout calculator, above, can give you an estimate for what you can expect to pay
        monthly with a buyout loan.
      </Text>
      <Text mb={4}>
        As you proceed with the buyout process via our online form, you’ll input information about
        your lease agreement, including the residual value, remaining payments, and personal
        information like credit score.
      </Text>
      <Text mb={4}>
        Once you have an estimate of the buyout cost, consider whether buying out your lease is the
        right option for you. Compare the buyout cost to the current market value of the vehicle to
        determine if it's a good deal.
      </Text>
    </Flex>
  );
};
export default Bottom;
