/* eslint-disable react/jsx-pascal-case */
import { Flex, Image, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';

import PageFor404s from '../404Page';
import {
  CivicGuy,
  DkLpBg,
  GoogleReviews,
  OrangeHeadset,
  ResultsCheck,
  TrustPilotIcon,
  VipConsult,
  VipNoDealer,
} from '../../assets/Images';
import Footer from '../../components/Footer';
import LicensePlateOrVinInput_VIPConsult from '../../components/LicensePlateOrVinInput_VIPConsult';
import LogoLink from '../../components/LogoLink';
import Nav from '../../components/Nav/Nav';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import { LDFlags } from '../../constants/experiments';
import { GOOGLE, TRUSTPILOT } from '../../constants/urls';
import { useDevice, useFlag } from '../../hooks';
import useCustomBreakpointCallback from '../../hooks/useCustomBreakpointCallback';
import { DevicesEnum } from '../../hooks/useDevice';
import { convertTimeZone } from '../../utils/dates';
import { fetchMarketingDeals } from '../../utils/globals';

const VIPGet = ({ image, title, body }: { image: string; title: string; body: string }) => {
  return (
    <Flex
      flex={1}
      bg="#f3f3f3"
      borderRadius={8}
      p={4}
      alignItems="flex-start"
      gap={4}
      mx={{
        base: 4,
        md: 0,
      }}
    >
      <Image src={image} h="78px" />
      <Flex flexDir="column">
        <Subtitle
          color="black"
          textTransform="uppercase"
          fontSize={18}
          fontWeight={800}
          m="0 !important"
          p={0}
        >
          {title}
        </Subtitle>
        <Text fontSize={16}>{body}</Text>
      </Flex>
    </Flex>
  );
};
export interface VipInfo {
  firstName?: string;
  lastName: string;
  make?: string;
  model?: string;
  year?: string;
}
const LPVIPConsultation = () => {
  const bpv = useCustomBreakpointCallback();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const isMobile = useDevice() === DevicesEnum.mobile;
  const EXP97 = useFlag(LDFlags.EXP97);
  const LM1235 = useFlag(LDFlags.LM1235);
  const [vip, setVip] = useState<VipInfo | null>(null);

  const tm = () => {
    console.log(
      convertTimeZone(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 'America/Los_Angeles'),
    );
  };

  useEffect(() => {
    tm();
  }, []);

  return (
    <>
      <Nav />
      {!LM1235 ? (
        <PageFor404s />
      ) : (
        <>
          <Flex
            justifyContent={{
              base: 'center',
              md: 'space-between',
            }}
            flex={1}
            mx="auto"
            mb={2}
            py={16}
            px={{
              base: 4,
              md: 16,
            }}
            bgImage={DkLpBg}
            backgroundPosition="bottom center"
            backgroundSize="cover"
            pb={200}
            flexDir="column"
            maxW="1820px"
          >
            <Flex
              flexDir="row"
              gap={0}
              justifyContent={{
                base: 'center',
                md: 'space-between',
              }}
              flexWrap={{
                base: 'wrap',
                md: 'wrap',
              }}
              display={{
                base: 'flex',
              }}
            >
              <Title
                textAlign={{
                  base: 'center',
                  md: 'left',
                }}
                fontSize={16}
                fontWeight={600}
                color="#FFF"
                m={0}
              >
                WELCOME BACK{` ${`${vip?.firstName?.toLocaleUpperCase()}`}` || ''}!
              </Title>
            </Flex>
            <Flex
              flexDir={{ base: 'column', lg: 'row' }}
              gap={0}
              justifyContent={{ base: 'center', lg: 'space-between' }}
              mt={2}
            >
              <Flex flexDir="column" gap={0}>
                <Title
                  textAlign={{ base: 'center', lg: 'left' }}
                  fontSize={{
                    base: 42,
                    md: '3.25rem',
                  }}
                  fontWeight={800}
                  color="#f6b528"
                  m={0}
                  lineHeight={{
                    base: 1.2,
                    md: 1.3,
                  }}
                  mb={4}
                >
                  {EXP97 || 'End your lease, keep your car.'}
                </Title>
                <Title
                  textAlign={{ base: 'center', lg: 'left' }}
                  fontSize={{
                    base: 24,
                    md: 32,
                  }}
                  fontWeight={700}
                  color="white"
                  m={0}
                  mt={{
                    base: 8,
                    md: 2,
                  }}
                >
                  End your{' '}
                  {vip?.make && (
                    <Text as="span" textTransform="capitalize">
                      {`${vip.make.toLocaleLowerCase()}'s`}
                    </Text>
                  )}{' '}
                  lease with a VIP phone consultation.
                </Title>
                <Subtitle
                  color="white"
                  css={{
                    margin: '24px 0 0 !important',
                    padding: '0 !important',
                  }}
                  fontSize={{
                    base: 18,
                    md: 21,
                  }}
                  lineHeight={1.5}
                  fontWeight={400}
                  textAlign={{ base: 'center', lg: 'left' }}
                >
                  We've helped{' '}
                  <Text as="span" fontWeight={700}>
                    {fetchMarketingDeals(true)}
                  </Text>{' '}
                  people end their lease and keep their car
                </Subtitle>
              </Flex>

              <Flex
                w={{
                  base: '100%',
                  md: 700,
                }}
                mt={12}
                mx={{
                  base: 0,
                  md: 16,
                }}
                pos="relative"
              >
                <LicensePlateOrVinInput_VIPConsult setVip={setVip} />
              </Flex>
            </Flex>
          </Flex>
          <Flex
            maxW="1200px"
            mt={{
              base: '-180px',
              md: '-200px',
            }}
            mb={12}
            justifyContent={{ base: 'center', md: 'space-between' }}
            alignItems="center"
            mx="auto"
            flexDir={{
              base: 'column',
              md: 'row',
            }}
          >
            <Flex
              pos="relative"
              justifyContent={{
                base: 'center',
                md: 'flex-start',
              }}
              w={{
                base: '100%',
                lg: 'auto',
              }}
            >
              <Image
                pos="relative"
                src={VipConsult}
                w={{
                  base: 350,
                  md: 300,
                  lg: 400,
                }}
                zIndex={2}
              />
              <Image
                src={CivicGuy}
                w={{
                  base: 330,
                  xl: 400,
                }}
                top="165px"
                right={{
                  base: '50px',
                  lg: '-350px',
                }}
                pos="absolute"
                zIndex={1}
                opacity={0.95}
                display={{
                  base: 'none',
                  md: bpv(
                    {
                      600: 'block',
                      1070: 'block',
                    },
                    true,
                  ),
                }}
              />
            </Flex>
            <Flex
              justifyContent={{
                base: 'center',
                md: 'flex-start',
              }}
              mt={{
                base: 16,
                md: 24,
              }}
              borderBottomWidth={{
                base: 1,
                md: 0,
              }}
              pb={{
                base: 8,
                md: 0,
              }}
            >
              <Flex
                gap={16}
                flexDir={{
                  base: 'column',
                }}
                flex={1}
              >
                <LogoLink
                  src={TrustPilotIcon}
                  link={TRUSTPILOT}
                  size={{
                    base: '90px',
                  }}
                  alt="Lease End's reviews from Trustpilot."
                  ariaLabel="Lease End's reviews from Trustpilot."
                  m="0 auto"
                />
                <LogoLink
                  src={GoogleReviews}
                  link={GOOGLE}
                  size={{
                    base: '90px',
                  }}
                  alt="Lease End's reviews from Google."
                  ariaLabel="Lease End's reviews from Google."
                  m="0 auto"
                />
              </Flex>
            </Flex>
          </Flex>
          <Flex mx="auto" justifyContent="center" flexDir="column" maxW="1200px" mb={16}>
            <Title color="black" fontWeight={800} mb={8}>
              Here's what you'll get with your free consultation
            </Title>
            <Flex
              flex={1}
              alignItems="center"
              justifyContent="center"
              gap={4}
              flexDir={{
                base: 'column',
                md: 'row',
              }}
            >
              <VIPGet
                image={OrangeHeadset}
                title="Expert guidance"
                body="Our trusted advisors will make ending your lease a breeze"
              />
              <VIPGet
                image={ResultsCheck}
                title="full Transparency"
                body="No sneaky fees, confusing rates or frustrating upsells"
              />
              <VIPGet
                image={VipNoDealer}
                title="hassle-free"
                body="We handle it all from financing to titling & registration!"
              />
            </Flex>
          </Flex>
        </>
      )}
      <Footer />
    </>
  );
};

export default LPVIPConsultation;
