/* eslint-disable security/detect-unsafe-regex */
import { PROMO_SUPER_BOWL, VIP_CONSULTATION } from '../constants/urls';

export const MARKETING_DEALS = '29,000';
export const EXCLUDE_SUPER_BOWL_PROMO = [PROMO_SUPER_BOWL, VIP_CONSULTATION];
export const fetchMarketingDeals = (addPlus: boolean) => {
  return addPlus ? `${MARKETING_DEALS}+` : MARKETING_DEALS;
};

export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const emailRegex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;

export enum VIP_PHONE_CONSTANTS {
  ImportType = 'MARKETING_NURTURE',
  TransformType = 'MARKETING_NURTURE',
  GuidExists = 'TEMP_INFO_NOT_FOUND',
}
