import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useUrlGuid = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [guid, setGuid] = useState<string | null>(null);
  useEffect(() => {
    setGuid(urlParams.get('guid') || null);
  }, []);
  return guid;
};

export default useUrlGuid;
