/* eslint-disable no-underscore-dangle */
import groq from 'groq';

import client from '../../client';
import {
  AuthorProps,
  BlogPostProps,
  CategoryProps,
  CompanyNewsProps,
  FAQCategoryProps,
  FAQProps,
  JobPostingProps,
  LEAssetFolderProps,
  LEAssetProps,
  LandingPageProps,
  PressReleaseProps,
  SalesProps,
  SanityPageProps,
} from './Model';

const PAGINATION_LIMIT = 10;

export const pageFetch = async (page: string) => {
  const data: SanityPageProps[] | null = await client.fetch(
    groq`
    *[_type == "page" && title=='${page}']{
        title,
        subtitle,
        heading,
        widget,
        body
      }
  `,
  );
  return data;
};

export const faqFetch = async (category?: string) => {
  const catWhere = category ? ` && '${category}' in categories[]->title` : '';
  const data: FAQProps[] | null = await client.fetch(
    groq`
      *[_type == "faq"${catWhere}]{
        question,
        answer,
        "publishDate": _updatedAt,
      }
  `,
  );
  return data;
};

export const faqCategoryFetch = async () => {
  const data: FAQCategoryProps[] | null = await client.fetch(
    groq`
      *[_type == "faqCategory"]|order(order){
        title,
      }
  `,
  );
  return data;
};

export const blogMenuFetch = async () => {
  const data: CategoryProps[] | null = await client.fetch(
    groq`
      *[_type == "category" && nav]|order(order){
        title,
      }
  `,
  );
  return data;
};

export const leAssetFetch = async (folder?: string) => {
  const where = folder ? ` && '${folder}' in folders[]->title` : '';
  const data: LEAssetProps[] | null = await client.fetch(
    groq`
      *[_type == "assets"${where}]{
        name, 
        url,
        description,
        "publishDate": _updatedAt,
      }
  `,
  );
  return data;
};

export const leAssetFolderFetch = async () => {
  const data: LEAssetFolderProps[] | null = await client.fetch(
    groq`
      *[_type == "assetFolders"]|order(order){
        title,
      }
  `,
  );
  return data;
};

export const blogFetch = async (category?: string | null) => {
  const _category = category ? ` && '${category}' in categories[]->title` : '';
  const data: [] | null = await client.fetch(
    groq`
        *[_type == "post" && published${_category}] | order(publishDate desc){
          title,
          "publishDate": _updatedAt,
          "authorName": author->name,
          "authorSlug": author->slug.current,
          "authorImage": author->image,
          "categories": categories[]->title,
          snippet,
          widget,
          slug,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const searchBlog = async (term?: string | null) => {
  const data: BlogPostProps[] | null = await client.fetch(
    groq`
        *[_type == "post" && published && (snippet match '${term}' || title match '${term}' || categories[]->title match '${term}')] | order(publishDate desc){
          title,
          "publishDate": _updatedAt,
          "authorName": author->name,
          "authorSlug": author->slug.current,
          "authorImage": author->image,
          "categories": categories[]->title,
          snippet,
          widget,
          body,
          slug,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const postFetch = async (slug: string) => {
  const data: BlogPostProps | null = await client.fetch(
    groq`
          *[_type == "post" && slug.current == $slug][0]{
            title,
            "publishDate": _updatedAt,
            "authorName": author->name,
            "authorBio": author->bio,
            "authorSlug": author->slug.current,
            "authorImage": author->image,
            "categories": categories[]->title,
            body,
            description,
            keywords,
            browserTitle,
            widget,
            mainImage
          }
      `,
    { slug },
  );
  return data;
};

export const featuredPostFetch = async () => {
  const data: BlogPostProps[] | null = await client.fetch(
    groq`
          *[_type == "post" && homepage] | order(_updatedAt desc){
            title,
          "publishDate": _updatedAt,
          "authorName": author->name,
          "authorSlug": author->slug.current,
          "authorImage": author->image,
          "categories": categories[]->title,
          snippet,
          slug,
          widget,
          mainImage
          }
      `,
  );
  return data;
};

export const authorFetch = async (slug: string, flavor?: string) => {
  const limit: string = flavor && flavor === 'recent' ? '[0...3]' : '';
  const data: AuthorProps | null = await client.fetch(
    groq`
          *[_type == "author" && slug.current == $slug][0]{
            "authorName": name,
            "authorImage": image,
            "authorSlug": slug.current,
            "authorBio": bio,
            "posts": *[_type == "post" && references(^._id) ]${limit}{
              title,
              "slug": slug.current,
              "publishDate": _updatedAt,
            }
          }
      `,
    { slug },
  );
  return data;
};

export const landingPageFetch = async (slug: string) => {
  const data: LandingPageProps | null = await client.fetch(
    groq`
          *[_type == "landingPage" && slug.current == $slug][0]{
            title,
            "publishDate": _updatedAt,
            "states": states[]->state,
            body,
            type,
            description,
            keywords,
            browserTitle,
          }
      `,
    { slug },
  );
  return data;
};

export const salesTeamFetch = async () => {
  const data: [] | null = await client.fetch(
    groq`
        *[_type == "sales"] | order(name asc){
          name, slug, title, phone, email, location, startDate, homeTown, leaseEndLove, image
        }
    `,
  );
  return data;
};

export const salesFetch = async (slug: string) => {
  const data: SalesProps | null = await client.fetch(
    groq`
          *[_type == "sales" && slug.current == $slug][0]{
            name, slug, title, phone, email, location, homeTown, hobbies, image, leaseEndLove
          }
      `,
    { slug },
  );
  return data;
};

export const jobPostingFetch = async () => {
  const data: JobPostingProps[] | null = await client.fetch(
    groq`
        *[_type == "jobs"] | order(department asc){
          name, location, department, url
        }
    `,
  );
  return data;
};

export const PRFetch = async (id: string | null, back?: boolean) => {
  const _srt = id === null ? '' : back ? `&& _id < ${id}` : `&& _id ? ${id}`;
  const data: PressReleaseProps[] | null = await client.fetch(
    groq`
        *[_type == "pressRelease" && published ${_srt}] | order(order asc, updateDate desc) [0...${PAGINATION_LIMIT}]{
          title,
          order,
          subtitle,
          body,
          publishLocation,
          publishDate,
          "updateDate": _updatedAt,
          slug,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const releaseFetch = async (slug: string) => {
  const data: PressReleaseProps | null = await client.fetch(
    groq`
          *[_type == "pressRelease" && slug.current == $slug][0]{
            title,
            order,
            subtitle,
            body,
            publishLocation,
            publishDate,
            "updateDate": _updatedAt,
            slug,
            "mainImage": mainImage
          }
      `,
    { slug },
  );
  return data;
};

export const companyNewsFetch = async (id: string | null, back?: boolean) => {
  const _srt = id === null ? '' : back ? `&& _id < ${id}` : `&& _id ? ${id}`;
  const data: CompanyNewsProps[] | null = await client.fetch(
    groq`
        *[_type == "companyNews" && published ${_srt}] | order(order asc, _id) [0...${PAGINATION_LIMIT}]{
          title,
          url,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const newsFetch = async (slug: string) => {
  const data: CompanyNewsProps | null = await client.fetch(
    groq`
        *[_type == "companyNews" && slug.current == $slug][0]{
          title,
          url,
          "mainImage": mainImage
        }
    `,
    { slug },
  );
  return data;
};
