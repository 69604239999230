import { Box, Divider, Flex, Image, ListItem, Text, UnorderedList } from '@chakra-ui/react';

import { AllyBankLogo } from '../../assets/Images';
import Subtitle from '../../components/Subtitle';
import Step from './Step';

const Aside = () => {
  return (
    <Flex
      minW="220px"
      flexDirection={{ sm: 'column', lg: 'row' }}
      ms={8}
      ps={8}
      borderStartWidth={1}
    >
      <Flex flexDirection="column" px={{ base: 6, md: 0 }}>
        <Subtitle fontSize={20} fontWeight="semibold">
          Buy Out Your Lease in 4 Easy Steps!
        </Subtitle>
        <Step fontColor="leaseEndBlue" stepNumber={1} title="Tell us about your car">
          We'll ask some easy questions about you & your lease to prepare for your purchase.
        </Step>
        <Step stepNumber={2} fontColor="leaseEndBlue" title="View your loan & coverage options">
          Review your unique financing & customizable vehicle coverage options.
        </Step>
        <Step stepNumber={3} fontColor="leaseEndBlue" title="Sign your buyout documents">
          We'll prepare the documents you need & you'll eSign securely on your Lease End account.
        </Step>
        <Step stepNumber={4} fontColor="leaseEndBlue" title="Relax - we'll take it from there">
          We'll arrange titling, registration, & new plates for you so you can skip the DMV trip.
        </Step>
        <Divider w="100%" bg="gray" my={4} pt="0.5px" />
        <Box color="leaseEndBlue">
          <Subtitle fontSize={20} fontWeight="semibold">
            Why choose Lease End?
          </Subtitle>
          <UnorderedList ml={8}>
            <ListItem>Expert tools & technology</ListItem>
            <ListItem>Professional advisory services</ListItem>
            <ListItem>No paperwork at the DMV or dealership</ListItem>
            <ListItem>No vehicle inspection</ListItem>
            <ListItem>No wear-and-tear or mileage fees</ListItem>
            <ListItem display="flex" alignItems="center">
              Extended vehicle coverage with{' '}
              <Image src={AllyBankLogo} height="16px" display="inline-block" ms="5px" />
            </ListItem>
          </UnorderedList>
        </Box>
        <Box color="leaseEndBlue" mt={4} pt={4} borderTopWidth={1}>
          <Subtitle fontSize={20} fontWeight="semibold">
            Tech to empower drivers like you
          </Subtitle>
          <Text>
            Lease End—a fintech powerhouse in the auto industry—is making lease buyouts accessible
            to everyone. We took the archaic lease-end process and turned it into something
            intuitive and approachable.
          </Text>
          <Text mt={5}>
            Assessing lease-end options, accessing info like buyout price & equity, and securing a
            lease buyout loan are all now easier than ever—because they’ve never been easy. Our
            innovative tech, created and supported by a formidable team of lease-end experts,
            provides lessees with the tools and resources they’ve always needed to make the best
            possible decision about a lease buyout, right at their fingertips.
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Aside;
