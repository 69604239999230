import { Box, Image, Text } from '@chakra-ui/react';

import { BigGameGiveaway2025, ExclusiveTag } from '../../assets/Images';

interface BigGameGiveawayProps {
  context?: string;
}

const BigGameGiveaway = ({ context = '' }: BigGameGiveawayProps) => {
  const bigGameJump = () => {
    window.location.href = '/big-game-giveaway';
  };
  return (
    <>
      <Box
        display="flex"
        top={context === 'UTM' ? 'auto' : '-16px'}
        pos={{
          base: context === 'UTM' ? 'relative' : 'absolute',
        }}
        zIndex={2}
        right={context === 'UTM' ? 'auto' : '236px'}
        alignItems="center"
        gap={3}
        bg="#f1cedc"
        p={0}
        px={3}
        height="64px"
        borderRadius={8}
        cursor="pointer"
        onClick={() => bigGameJump()}
        justifyContent={context === 'UTM' ? 'space-between' : 'flex-start'}
      >
        <Image src={ExclusiveTag} w="48px" pos="absolute" top="-8px" left="-9px" />
        <Box
          display={{
            base: 'none',
            lg: 'flex',
          }}
          flexDir="column"
          gap={0}
        >
          <Text
            textAlign="right"
            color="#661a38"
            fontWeight={900}
            fontSize={18}
            textTransform="uppercase"
            lineHeight={1.3}
          >
            Win 2 tickets to the Big Game!
          </Text>
          <Text fontSize={12} color="#661a38" lineHeight={1.3}>
            Go to football's biggest game in New Orleans!
          </Text>
        </Box>
        <Image src={BigGameGiveaway2025} width="42px" />
      </Box>
    </>
  );
};

export default BigGameGiveaway;
