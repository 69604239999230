import { Container, Flex, Image, Text } from '@chakra-ui/react';

import { Idaho } from '../../assets/Images';
import Description from '../../components/Description';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { Link } from '../../components/ui/links/Link';
import { useGetPodHoursQuery } from '../../gql/generated/graphql';
import { useSearchPhoneNumbers } from '../../hooks';

const ContactUs = () => {
  const phoneNumber = useSearchPhoneNumbers();
  const { data } = useGetPodHoursQuery({ fetchPolicy: 'network-only' });
  const podHours = data?.podHours;

  const getHourTime = (time: string) => {
    const hour = parseInt(time.split(':')[0], 10);
    const suffex = hour >= 12 ? 'PM' : 'AM';
    const hours = hour > 12 ? hour - 12 : hour;
    const minutes = time.split(':')[1];
    return `${hours}:${minutes} ${suffex}`;
  };

  if (!podHours) {
    return null;
  }

  return (
    <Container maxW="container.lg">
      <TitleContainer>
        <Title fontSize={{ base: '36px', md: '52px' }}>Talk to a Lease End Expert today!</Title>
      </TitleContainer>
      <Subtitle textAlign="center" mb={{ md: '50px' }}>
        You have questions, we have answers. Give our experts a call today and they can answer any
        questions you have about your auto lease. They probably can't answer life's big questions or
        help you with your Calculus homework but they will knock any questions you have about leases
        out of the park!
      </Subtitle>
      <Flex
        alignItems="center"
        direction={{ base: 'column', md: 'row' }}
        mb={{ base: '35px', md: '50px' }}
        justifyContent={{ md: 'center' }}
        borderBottomWidth="2px"
      >
        <Flex
          alignItems={{ base: 'center', md: 'flex-start' }}
          direction="column"
          m={{ base: '0 auto', md: 0 }}
          mt={6}
        >
          <Description m="10px 0" fontSize={{ base: '18px', md: '20px' }}>
            Give Us a Call:
          </Description>
          <Link
            fontSize={{ base: '24px', md: '28px' }}
            fontWeight="bold"
            to={`tel:+1-${phoneNumber}`}
          >
            {phoneNumber}
          </Link>
        </Flex>
        <Flex mt={{ base: '25px', md: 0 }}>
          <Image m="0 15px 0 50px" src={Idaho} />
          <Description m="10px 0" fontSize={{ base: '14px', md: '20px' }}>
            Lease End
            <br />
            411 Overland Ave.
            <br />
            Burley, ID 83318
          </Description>
        </Flex>
      </Flex>
      <Description fontWeight="medium" fontSize="17px" mb="5px" textAlign="center">
        OUR HOURS (M.T.)
      </Description>
      <Flex direction="column" mb="50px">
        {podHours.map((day) => (
          <Flex
            fontWeight="normal"
            justifyContent="center"
            key={day?.id}
            fontSize={{ base: '14px', md: '18px' }}
            color="midnightBlueDark"
            lineHeight={{ lg: '26px' }}
          >
            <Text textAlign="left" w={{ base: '120px', md: '200px' }}>
              {day?.label}
            </Text>
            {day?.endTime ? (
              <Text textAlign="center" w={{ base: '150px', md: '200px' }} fontWeight="medium">
                {getHourTime(day?.beginTime ?? '')} - {getHourTime(day?.endTime ?? '')}
              </Text>
            ) : (
              <Text textAlign="center" w={{ base: '150px', md: '200px' }} fontWeight="medium">
                CLOSED
              </Text>
            )}
          </Flex>
        ))}
      </Flex>
    </Container>
  );
};

export default ContactUs;
