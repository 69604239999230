import { CompanyNewsProps } from '../Sanity/Model';
import NewsDetail from './NewsDetail';

interface NewsProps {
  news: CompanyNewsProps;
}

const News = ({ news }: NewsProps) => {
  return <NewsDetail companyNews={news} full={false} />;
};

export default News;
