import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { FaChevronDown } from 'react-icons/fa6';
import { IoIosMenu, IoMdLock } from 'react-icons/io';
import { MdPerson } from 'react-icons/md';
import { RiAccountCircleFill } from 'react-icons/ri';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import {
  BigGameGiveaway2025,
  Logo,
  LogoWhite,
  PaymentCalculatorIcon,
  PaymentCalculatorIconWhite,
  SmallLogo,
  SmallLogoWhite,
  TrustPilotIcon,
} from '../../assets/Images';
import {
  ABOUT_US,
  ABOUT_US_SALES,
  ACCOUNT_LOGIN,
  BLOG_SANITY,
  DASHBOARD,
  ENDORSEMENTS,
  FAQS,
  HOME,
  HOW_IT_WORKS,
  MONTHLY_PAYMENT_CALCULATOR,
  PRESS, // REFI,
  REVIEWS,
  TRUSTPILOT,
} from '../../constants/urls';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import useSearchPhoneNumbers from '../../hooks/useSearchPhoneNumbers';
import useSuperBowl from '../../hooks/useSuperBowl';
import useWindowSize from '../../hooks/useWindowSize';
import LogoLink from '../LogoLink';
import NavLink from './NavLink';

export const FAQ_BREAKPOINT = 1000;
export const LEARN_BREAKPOINT = 1050;
export const ABOUT_US_BREAKPOINT = 1100;
export const REVIEWS_BREAKPOINT = 1150;

interface NavProps {
  inFlow?: boolean;
  hideMenuItems?: boolean;
  inverse?: boolean;
}

const DD_ABOUT_US: {
  url: string;
  label: string;
}[] = [
  {
    url: ABOUT_US,
    label: 'About Us',
  },
  {
    url: ENDORSEMENTS,
    label: 'Endorsements',
  },
  {
    url: REVIEWS,
    label: 'Reviews',
  },
  {
    url: ABOUT_US_SALES,
    label: 'Our Team',
  },
  {
    url: PRESS,
    label: 'Press',
  },
];

const Nav = ({ inFlow = false, hideMenuItems = false, inverse = false }: NavProps) => {
  const isDesktop = useBreakpointValue({
    base: false,
    md: true,
  });

  const showSmallerLogo = useBreakpointValue({
    base: false,
    md: true,
    lg: false,
  });

  const navColor: string = inverse ? 'white' : 'leaseEndBlue';
  const secondaryNavColor: string = inverse ? 'white' : 'oceanBoatBlue';

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const phoneNumber = useSearchPhoneNumbers();
  const { windowWidth } = useWindowSize();
  const [isSmallerThan355] = useMediaQuery('(max-width: 355px)');

  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;

  const hideMenuLinks = hideMenuItems || inFlow;
  const showAuthLink = isAuthenticated && !inFlow;
  const showLoginBtn = !isAuthenticated && !inFlow;

  const [ddAboutUs, setDdAboutUs] = useState<boolean>(false);
  const [activeAboutUs, setActiveAboutUs] = useState<boolean>(false);

  const superBowl: boolean = useSuperBowl();

  const handleAboutUsDD = (open: boolean) => {
    setDdAboutUs(open);
  };

  const handleSubNav = () => {
    // About us
    setActiveAboutUs(
      DD_ABOUT_US.filter((obj) => {
        return obj.url === pathname && pathname !== HOME;
      }).length > 0,
    );
  };

  useEffect(() => {
    handleSubNav();
  }, [pathname]);

  const bigGameJump = () => {
    window.location.href = '/big-game-giveaway';
  };

  return (
    <>
      {superBowl && (
        <Flex
          bg="#f1cedc"
          color="white"
          justifyContent="center"
          alignItems="center"
          flexDir={{
            base: 'row',
            md: 'row',
          }}
          gap={4}
          py={1}
        >
          <Flex borderRadius={4} p={2}>
            <Image
              src={BigGameGiveaway2025}
              w={{
                base: '72px',
                md: '64px',
              }}
            />
          </Flex>
          <Flex flexDir="column" justifyContent="center" textAlign="center">
            <Text
              textAlign={{
                base: 'left',
                md: 'center',
              }}
              color="#661a38"
              fontWeight={900}
              fontSize={{
                base: 20,
                md: 28,
              }}
              lineHeight={1.3}
              textTransform="uppercase"
              display={{
                base: 'block',
                md: 'block',
              }}
            >
              Win 2 Tickets to Football's Biggest Game!
              <Link
                display={{
                  base: 'inline-block',
                  md: 'none',
                }}
                ms={2}
                fontWeight={600}
                textTransform="capitalize"
                fontSize={14}
                onClick={() => bigGameJump()}
              >
                Details
              </Link>
            </Text>
            <Text
              color="#661a38"
              fontWeight={500}
              fontSize={16}
              lineHeight={1.3}
              display={{
                base: 'none',
                md: 'block',
              }}
            >
              End your lease with Lease End and get a chance to go to football's biggest game!
            </Text>
          </Flex>
          <Flex
            w="100px"
            display={{
              base: 'none',
              md: 'flex',
            }}
          >
            <Button
              bg="#ff2b16"
              color="white"
              fontWeight={700}
              fontSize={{
                base: 12,
                md: 14,
              }}
              p={{
                base: 3,
                md: 4,
              }}
              cursor="pointer"
              textTransform="uppercase"
              onClick={() => bigGameJump()}
              _hover={{
                background: '#ff2b16',
              }}
            >
              DETAILS
            </Button>
          </Flex>
        </Flex>
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p={{ base: '10px', md: '15px 20px' }}
        maxWidth="1920px"
        m="0 auto"
        position="relative"
        zIndex="1"
        background="transparent"
      >
        {isDesktop ? (
          <>
            <Flex
              alignItems="center"
              gap={inFlow ? 'unset' : '30px'}
              justifyContent={inFlow ? 'space-between' : 'unset'}
            >
              {inFlow && showSmallerLogo ? (
                <Link>
                  <ReactRouterLink to={HOME}>
                    <Image
                      src={inverse ? SmallLogoWhite : SmallLogo}
                      alt="Lease End Logo"
                      w="40px"
                    />
                  </ReactRouterLink>
                </Link>
              ) : (
                <Link minW={hideMenuLinks ? '220px' : 'unset'}>
                  <ReactRouterLink to={HOME}>
                    <Image src={inverse ? LogoWhite : Logo} alt="Lease End Logo" w="130px" />
                  </ReactRouterLink>
                </Link>
              )}
              {!hideMenuLinks && (
                <>
                  <NavLink color={navColor} to={HOW_IT_WORKS}>
                    How it Works
                  </NavLink>
                  {windowWidth > FAQ_BREAKPOINT && (
                    <NavLink color={navColor} to={FAQS}>
                      FAQs
                    </NavLink>
                  )}
                  {windowWidth > LEARN_BREAKPOINT && (
                    <>
                      <NavLink
                        color={navColor}
                        to={BLOG_SANITY}
                        // eslint-disable-next-line react/no-children-prop
                        isExternal={false}
                      >
                        Learn
                      </NavLink>
                    </>
                  )}
                  {windowWidth > ABOUT_US_BREAKPOINT && (
                    <>
                      <Flex
                        pos="relative"
                        onMouseEnter={() => handleAboutUsDD(true)}
                        onMouseLeave={() => handleAboutUsDD(false)}
                      >
                        <NavLink
                          _hover={{
                            textDecor: 'none',
                          }}
                          color={activeAboutUs ? 'oceanBoatBlue' : navColor}
                          fontWeight={activeAboutUs ? 800 : 600}
                        >
                          About Us
                          <IconContext.Provider
                            value={{
                              size: '14px',
                              style: {
                                marginLeft: '8px',
                              },
                            }}
                          >
                            <FaChevronDown />
                          </IconContext.Provider>
                        </NavLink>
                        {ddAboutUs && (
                          <Flex
                            pos="absolute"
                            left={0}
                            top="24px"
                            bg="white"
                            minW={180}
                            flexDir="column"
                            boxShadow="0 8px 10px rgba(0,0,0,0.2)"
                          >
                            {DD_ABOUT_US.map(({ url, label }) => {
                              return (
                                <Link
                                  fontSize={15}
                                  p={2}
                                  borderStartWidth={3}
                                  borderColor={pathname === url ? '#087dc5' : '#fff'}
                                  bg={pathname === url ? '#f5f5f5' : '#fff'}
                                  fontWeight={600}
                                  color={pathname === url ? '#087dc5' : '#333'}
                                  _hover={{
                                    textDecor: 'none',
                                    color: pathname === url ? '#087dc5' : '#333',
                                    bg: '#f5f5f5',
                                    borderColor: pathname === url ? '#087dc5' : '#f5f5f5',
                                  }}
                                  href={url}
                                >
                                  {label}
                                </Link>
                              );
                            })}
                          </Flex>
                        )}
                      </Flex>
                    </>
                  )}
                  {/* {pathname !== '/refi' && refinance_landing_page ? (
                  <Flex alignItems="center" gap="10px" borderLeft="solid 2px #0000002E" pl="24px">
                    <IconContext.Provider
                      value={{
                        size: '26',
                        color: inverse ? 'white' : '#087dc5',
                      }}
                    >
                      <FaMoneyBillTransfer />
                    </IconContext.Provider>
                    <Link color={secondaryNavColor} fontSize="16px" fontWeight="semibold">
                      <ReactRouterLink to={REFI}>Refinance</ReactRouterLink>
                    </Link>
                  </Flex>
                ) : refinance_landing_page ? (
                  <Flex alignItems="center" gap="10px" borderLeft="solid 2px #0000002E" pl="24px">
                    <IconContext.Provider
                      value={{
                        size: '26',
                        color: inverse ? 'white' : '#087dc5',
                      }}
                    >
                      <PiBankBold />
                    </IconContext.Provider>
                    <Link color="oceanBoatBlue" fontSize="16px" fontWeight="semibold">
                      <ReactRouterLink to={HOME}>Buyout Lease</ReactRouterLink>
                    </Link>
                  </Flex>
                ) : null} */}
                  {pathname !== '/monthly-payment-calculator' && (
                    <Flex alignItems="center" gap="10px">
                      <Image
                        w={14}
                        src={inverse ? PaymentCalculatorIconWhite : PaymentCalculatorIcon}
                        alt="Payment Calculator Icon"
                        borderLeft="solid 2px #0000002E"
                        pl="24px"
                      />
                      <Link color={secondaryNavColor} fontSize="16px" fontWeight="semibold">
                        <ReactRouterLink to={MONTHLY_PAYMENT_CALCULATOR}>
                          Calculator
                        </ReactRouterLink>
                      </Link>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
            <Flex alignItems="center" gap="40px">
              {!hideMenuLinks && (
                <>
                  {showAuthLink && (
                    <Link href={DASHBOARD}>
                      <Button
                        aria-label="Profile"
                        p={0}
                        variant="ghost"
                        _active={{ outline: 'none', bg: 'transparent' }}
                        _focus={{ outline: 'none', bg: 'transparent' }}
                        _hover={{ bg: 'transparent' }}
                        leftIcon={<Icon as={MdPerson} boxSize="26px" />}
                        mr={4}
                        color="oceanBoatBlue"
                      >
                        Dashboard
                      </Button>
                    </Link>
                  )}
                  <LogoLink
                    src={TrustPilotIcon}
                    link={TRUSTPILOT}
                    size="40px"
                    alt="Lease End's reviews from Trustpilot."
                    ariaLabel="Lease End's reviews from Trustpilot."
                    m="0 auto"
                  />
                  {showLoginBtn && (
                    <Link href={ACCOUNT_LOGIN}>
                      <Button
                        leftIcon={<IoMdLock size="20px" color="white" />}
                        fontSize="16px"
                        backgroundColor="oceanBoatBlue"
                        color="white"
                        padding="20px 17.5px"
                        _hover={{
                          boxShadow: 'md',
                          backgroundColor: 'royalBlueLight',
                        }}
                      >
                        Log in
                      </Button>
                    </Link>
                  )}
                </>
              )}
              {!showLoginBtn && !showAuthLink && (
                <>
                  <Box display={{ base: 'none', lg: 'block' }} mr="10px">
                    <Box mb="-5px" mt="5px">
                      Give Us a Call:
                    </Box>
                    <Link
                      fontSize="2rem"
                      fontWeight="bold"
                      _hover={{ textDecor: 'none' }}
                      href={`tel:+1-${phoneNumber}`}
                      aria-label="Phone number"
                      color="leaseEndBlue"
                    >
                      {phoneNumber}
                    </Link>
                  </Box>
                  <Box display={{ base: 'block', lg: 'none' }}>
                    <Link fontSize="2rem" href={`tel:+1-${phoneNumber}`} aria-label="Phone Number">
                      <FaPhoneAlt />
                    </Link>
                  </Box>
                </>
              )}
            </Flex>
          </>
        ) : (
          <>
            <Flex direction={isSmallerThan355 ? 'column' : 'row'}>
              <Link href={HOME} mx={hideMenuLinks ? 'auto' : undefined} mb={1}>
                <Image src={inverse ? LogoWhite : Logo} alt="Lease End Logo" w="130px" />
              </Link>
            </Flex>
            {!hideMenuLinks && (
              <>
                <Flex pr="10px">
                  <Icon
                    as={IoIosMenu}
                    boxSize="40px"
                    onClick={onOpen}
                    color={inverse ? 'white' : 'oceanBoatBlue'}
                  />
                </Flex>
                <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton color="white" />
                    <DrawerBody bg="matisseBlue" overflow="hidden">
                      {showLoginBtn && (
                        <Flex alignItems="center" gap="5px">
                          <Icon as={RiAccountCircleFill} boxSize="30px" color="white" />
                          <Link
                            href={ACCOUNT_LOGIN}
                            mt="3px"
                            color="white"
                            textTransform="uppercase"
                            _hover={{ color: 'white', textDecor: 'underline' }}
                          >
                            Login
                          </Link>
                        </Flex>
                      )}
                      {showAuthLink && (
                        <Link href={DASHBOARD}>
                          <Button
                            aria-label="Profile"
                            p={0}
                            variant="ghost"
                            leftIcon={<Icon as={MdPerson} boxSize="26px" />}
                            mr={4}
                            color="white"
                          >
                            Dashboard
                          </Button>
                        </Link>
                      )}
                      <Divider m="10px 0" />
                      <Flex flexDir="column" gap="15px" mt="10px">
                        <NavLink
                          color="white"
                          to={HOME}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Home
                        </NavLink>
                        {/* {refinance_landing_page && (
                        <NavLink
                          color="white"
                          to={REFI}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Refinance
                        </NavLink>
                      )} */}
                        <NavLink
                          onClick={onClose}
                          color="white"
                          to={HOW_IT_WORKS}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          How it Works
                        </NavLink>
                        <NavLink
                          onClick={onClose}
                          color="white"
                          to={FAQS}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          FAQs
                        </NavLink>
                        <NavLink
                          onClick={onClose}
                          color="white"
                          isExternal={false}
                          to={BLOG_SANITY}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Learn
                        </NavLink>
                        <NavLink
                          onClick={onClose}
                          color="white"
                          to={ABOUT_US}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          About Us
                        </NavLink>
                        <NavLink
                          onClick={onClose}
                          color="white"
                          to={ENDORSEMENTS}
                          ms={8}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Endorsements
                        </NavLink>
                        <NavLink
                          onClick={onClose}
                          color="white"
                          to={REVIEWS}
                          ms={8}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Reviews
                        </NavLink>
                        <NavLink
                          onClick={onClose}
                          color="white"
                          to={ABOUT_US_SALES}
                          ms={8}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Our Team
                        </NavLink>
                        <NavLink
                          onClick={onClose}
                          color="white"
                          to={MONTHLY_PAYMENT_CALCULATOR}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Payment Calculator
                        </NavLink>
                      </Flex>
                      <Divider m="10px 0" />
                      <Text color="white" fontSize="20px">
                        Give us a call
                      </Text>
                      <Link
                        href={`tel:${phoneNumber}`}
                        color="white"
                        fontSize="20px"
                        fontWeight="bold"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        {phoneNumber}
                      </Link>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default Nav;
